import { MulticallUpdater } from "@/state/multicall";
import TransactionUpdater from "@/state/transactions/updater";
import { BlockUpdater } from "@/hooks/useBlockNumber";

export default function Updaters() {
  return (
    <>
      <TransactionUpdater />
      <MulticallUpdater />
      <BlockUpdater />
    </>
  );
}
