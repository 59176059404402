export const formatAccount = (account: string): string => {
  return account.slice(0, 6) + "..." + account.slice(-4);
};

export const formatCurrencySymbol = (symbol: string): string => {
  if (symbol.length > 20) {
    return symbol.slice(0, 4) + "..." + symbol.slice(-5);
  } else {
    return symbol;
  }
};

export const formatValue = (
  value?: number | string | null,
  fractionDigits = 0,
  useGrouping = true
): string | "-" => {
  if (value === null || value === undefined || isNaN(+value)) {
    return "-";
  }
  const numberSplitRegexp = new RegExp(
    `^[\\s0]*(\\d+)?(\\.\\d{0,${fractionDigits}})?.*$`
  );
  return value
    .toString()
    .replace(numberSplitRegexp, function (_m, integer = "0", fraction) {
      return (
        (useGrouping
          ? integer.replace(/\d(?=(\d{3})+$)/g, function (n: string) {
              return n + ",";
            })
          : integer) +
        (fractionDigits > 0
          ? (fraction ?? ".").padEnd(fractionDigits + 1, 0)
          : "")
      );
    });
};

const unitFormatRule = {
  BTC: [8],
  WBTC: [8],
  GT: [6],
  STD: [4],
  USD: [2],
  percentage: [2, false],
} as const;

export type KeyOfUnitFormatRule = keyof typeof unitFormatRule;
function isKeyofUnitFormatRule(key: string): key is KeyOfUnitFormatRule {
  return key in unitFormatRule;
}
export function formatDisplayValue(
  value?: number | string | null,
  unit?: KeyOfUnitFormatRule,
  useGrouping?: boolean
) {
  if (unit && unit in unitFormatRule) {
    const rule = unitFormatRule[unit];
    return formatValue(value, rule[0], useGrouping ?? rule[1]);
  } else {
    return formatValue(value, 2, useGrouping);
  }
}

export function formatToken(
  value: number | string | null,
  tokenName: string,
  useGrouping?: boolean
) {
  const unit = tokenName.toUpperCase();
  return formatDisplayValue(
    value,
    isKeyofUnitFormatRule(unit) ? unit : "STD",
    useGrouping
  );
}

export function getTokenFixedPoint(tokenName: string) {
  const unit = tokenName.toUpperCase();
  const rule = unitFormatRule[isKeyofUnitFormatRule(unit) ? unit : "STD"];
  return rule[0];
}

export function formatRemainingTime({
  days,
  hours,
  minutes,
  seconds,
  delimiter = ":",
}: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  delimiter?: string;
}) {
  const units = ["D", "H", "M", "S"];
  return [days, hours, minutes, seconds]
    .reduce<string[]>((acc, cur, i) => {
      if (typeof cur === "number") {
        acc.push(cur.toString().padStart(2, "0") + units[i]);
      }
      return acc;
    }, [])
    .join(delimiter);
}

export function formatLocaleTime(value: string | number) {
  const date = new Date(value);

  return `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${date.getFullYear()} ${date
    .getHours()
    .toString()
    .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
    .getSeconds()
    .toString()
    .padStart(2, "0")}`;
}

export function formatUTCTime(value: number, timezone = true) {
  // 24/01/2022 10:21:14 (UTC+0)
  const date = new Date(value);

  return `${date.getUTCDate().toString().padStart(2, "0")}/${(
    date.getUTCMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${date.getUTCFullYear()} ${date
    .getUTCHours()
    .toString()
    .padStart(2, "0")}:${date
    .getUTCMinutes()
    .toString()
    .padStart(2, "0")}:${date.getUTCSeconds().toString().padStart(2, "0")}${
    timezone ? " (UTC+0)" : ""
  }`;
}

export function formatMonthDescTime(value: string) {
  if (isNaN(parseInt(value))) {
    return "-";
  }
  const cTime = new Date(value);
  const day = cTime.getUTCDate();
  const month = cTime.getUTCMonth();
  const year = cTime.getUTCFullYear();
  let monthDesc = "";
  switch (month) {
    case 0:
      monthDesc = "Jan";
      break;
    case 1:
      monthDesc = "Feb";
      break;
    case 2:
      monthDesc = "Mar";
      break;
    case 3:
      monthDesc = "Apr";
      break;
    case 4:
      monthDesc = "May";
      break;
    case 5:
      monthDesc = "Jun";
      break;
    case 6:
      monthDesc = "Jul";
      break;
    case 7:
      monthDesc = "Aug";
      break;
    case 8:
      monthDesc = "Sept";
      break;
    case 9:
      monthDesc = "Oct";
      break;
    case 10:
      monthDesc = "Nov";
      break;
    case 11:
      monthDesc = "Dec";
      break;
    default:
      break;
  }
  return `${monthDesc} ${day}, ${year} (UTC+0)`;
}

export const monthCodeName = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export function formatMonth(value: string | number) {
  const cTime = new Date(value);
  const month = cTime.getMonth();

  return monthCodeName[month];
}
