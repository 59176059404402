import { ReactNode, useCallback } from "react";
import { useUserLocaleManager } from "./applications/DEX/state/user/hooks";
import { DEFAULT_LOCALE, SupportedLocale } from "./constants/locales";
import { initialLocale, useActiveLocale } from "./hooks/useActiveLocale";
import { i18n } from "@lingui/core";
import { dynamicActivate, Provider } from "@/lib/i18n";
import { messages } from "./locales/en/messages";
// i18n.loadLocaleData({
//   en: { plurals: en },
//   zh: { plurals: zh },
// });

// export async function dynamicActivate(locale: string) {
//   const { messages } = await import(`./locales/${locale}/messages`);
//   i18n.load(locale, messages);
//   i18n.activate(locale);
// }

i18n.load(DEFAULT_LOCALE, messages);
i18n.activate(DEFAULT_LOCALE);

dynamicActivate(initialLocale);

export function LanguageProvider({ children }: { children: ReactNode }) {
  const locale = useActiveLocale();
  const [, setUserLocale] = useUserLocaleManager();

  const onActivate = useCallback(
    (locale: SupportedLocale) => {
      document.documentElement.setAttribute("lang", locale);
      setUserLocale(locale); // stores the selected locale to persist across sessions
    },
    [setUserLocale]
  );

  return (
    <Provider
      locale={locale}
      forceRenderAfterLocaleChange={true}
      onActivate={onActivate}
    >
      {children}
    </Provider>
  );
}
