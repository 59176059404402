import { createReducer } from "@reduxjs/toolkit";

import { DEFAULT_DEADLINE_FROM_NOW } from "../../constants/misc";
import { updateVersion } from "@/state/global/actions";
import {
  SerializedToken,
  updateUserClientSideRouter,
  updateUserDeadline,
  updateUserExpertMode,
  updateUserSlippageTolerance,
  addSerializedToken,
  removeSerializedToken,
  SerializedPair,
  updateUserLocale,
} from "./actions";
import { SupportedLocale } from "@/constants/locales";

const currentTimestamp = () => new Date().getTime();

export interface UserState {
  // the timestamp of the last updateVersion action
  lastUpdateVersionTimestamp?: number;
  userExpertMode: boolean;
  userClientSideRouter: boolean; // whether routes should be calculated with the client side router only
  userSlippageTolerance: number | "auto";
  userSlippageToleranceHasBeenMigratedToAuto: boolean; // temporary flag for migration status
  // deadline set by user in minutes, used in all txns
  userDeadline: number;
  timestamp: number;
  tokens: {
    [chainId: number]: {
      [address: string]: SerializedToken;
    };
  };
  pairs: {
    [chainId: number]: {
      // keyed by token0Address:token1Address
      [key: string]: SerializedPair;
    };
  };
  userLocale: SupportedLocale | null;
}

export const initialState: UserState = {
  userExpertMode: false,
  userClientSideRouter: false,
  userSlippageTolerance: "auto",
  userSlippageToleranceHasBeenMigratedToAuto: true,
  userDeadline: DEFAULT_DEADLINE_FROM_NOW,
  timestamp: currentTimestamp(),
  tokens: {},
  pairs: {},
  userLocale: null,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateVersion, (state) => {
      // slippage isnt being tracked in local storage, reset to default
      // noinspection SuspiciousTypeOfGuard
      if (
        typeof state.userSlippageTolerance !== "number" ||
        !Number.isInteger(state.userSlippageTolerance) ||
        state.userSlippageTolerance < 0 ||
        state.userSlippageTolerance > 5000
      ) {
        state.userSlippageTolerance = "auto";
      } else {
        if (
          !state.userSlippageToleranceHasBeenMigratedToAuto &&
          [10, 50, 100].indexOf(state.userSlippageTolerance) !== -1
        ) {
          state.userSlippageTolerance = "auto";
          state.userSlippageToleranceHasBeenMigratedToAuto = true;
        }
      }

      // deadline isnt being tracked in local storage, reset to default
      // noinspection SuspiciousTypeOfGuard
      if (
        typeof state.userDeadline !== "number" ||
        !Number.isInteger(state.userDeadline) ||
        state.userDeadline < 60 ||
        state.userDeadline > 180 * 60
      ) {
        state.userDeadline = DEFAULT_DEADLINE_FROM_NOW;
      }

      state.lastUpdateVersionTimestamp = currentTimestamp();
    })

    .addCase(updateUserExpertMode, (state, action) => {
      state.userExpertMode = action.payload.userExpertMode;
      state.timestamp = currentTimestamp();
    })
    .addCase(updateUserSlippageTolerance, (state, action) => {
      state.userSlippageTolerance = action.payload.userSlippageTolerance;
      state.timestamp = currentTimestamp();
    })
    .addCase(updateUserDeadline, (state, action) => {
      state.userDeadline = action.payload.userDeadline;
      state.timestamp = currentTimestamp();
    })
    .addCase(updateUserClientSideRouter, (state, action) => {
      state.userClientSideRouter = action.payload.userClientSideRouter;
    })
    .addCase(addSerializedToken, (state, { payload: { serializedToken } }) => {
      if (!state.tokens) {
        state.tokens = {};
      }
      state.tokens[serializedToken.chainId] =
        state.tokens[serializedToken.chainId] || {};
      state.tokens[serializedToken.chainId][serializedToken.address] =
        serializedToken;
      state.timestamp = currentTimestamp();
    })
    .addCase(
      removeSerializedToken,
      (state, { payload: { address, chainId } }) => {
        if (!state.tokens) {
          state.tokens = {};
        }
        state.tokens[chainId] = state.tokens[chainId] || {};
        delete state.tokens[chainId][address];
        state.timestamp = currentTimestamp();
      }
    )
    .addCase(updateUserLocale, (state, action) => {
      state.userLocale = action.payload.userLocale;
      state.timestamp = currentTimestamp();
    })
);
