import { SupportedLocale } from "@/constants/locales";
import { createAction } from "@reduxjs/toolkit";
import type { ConnectionType } from "@/constants/wallets";

export interface SerializedToken {
  chainId: number;
  address: string;
  decimals: number;
  symbol?: string;
  name?: string;
}

export interface SerializedPair {
  token0: SerializedToken;
  token1: SerializedToken;
}

export const updateUserClientSideRouter = createAction<{
  userClientSideRouter: boolean;
}>("user/updateUserClientSideRouter");

export const updateUserDeadline = createAction<{ userDeadline: number }>(
  "user/updateUserDeadline"
);
export const addSerializedToken = createAction<{
  serializedToken: SerializedToken;
}>("user/addSerializedToken");
export const updateUserSlippageTolerance = createAction<{
  userSlippageTolerance: number | "auto";
}>("user/updateUserSlippageTolerance");
export const updateUserExpertMode = createAction<{ userExpertMode: boolean }>(
  "user/updateUserExpertMode"
);

export const removeSerializedToken = createAction<{
  chainId: number;
  address: string;
}>("user/removeSerializedToken");

export const addSerializedPair = createAction<{
  serializedPair: SerializedPair;
}>("user/addSerializedPair");
export const removeSerializedPair = createAction<{
  chainId: number;
  tokenAAddress: string;
  tokenBAddress: string;
}>("user/removeSerializedPair");

export const updateUserLocale = createAction<{ userLocale: SupportedLocale }>(
  "user/updateUserLocale"
);
export const updateSelectedWallet = createAction<{ wallet?: ConnectionType }>(
  "user/updateSelectedWallet"
);
