import UIAlign from "@/ui/Align";
import { Space } from "antd";
import cn, { Argument } from "classnames";
import { ReactNode } from "react";
import style from "./style.module.scss";

const WalletModalOption: React.FunctionComponent<{
  disabled?: boolean;
  className?: Argument;
  icon: string;
  title: string;
  subtitle?: string;
  children?: ReactNode;
  onClick?: () => void;
}> = ({
  title,
  subtitle,
  icon,
  className,
  onClick,
  children,
  disabled = false,
}) => {
  return (
    <UIAlign
      align="center"
      onClick={onClick}
      className={cn(
        style.walletItem,
        {
          [style.disabled]: disabled,
        },
        className
      )}
      gap={14}
    >
      <img className={style.icon} src={icon} alt={title} />
      <Space direction="vertical" size={8}>
        <div className={style.title}>{title}</div>
        {subtitle && <div className={style.subtitle}>{subtitle}</div>}
      </Space>
      {children}
    </UIAlign>
  );
};

export default WalletModalOption;
