import { Modal, ModalProps } from "antd";
import style from "./style.module.scss";
import { ReactComponent as CloseIcon } from "@/assets/svg/close.svg";
import { ReactNode } from "react";

interface UIModalProps extends ModalProps {
  logo?: ReactNode;
}
const UIModal: React.FunctionComponent<UIModalProps> = ({
  children,
  logo,
  ...props
}) => {
  const closeIcon = (
    <div className={props.title ? "" : style.close}>
      <CloseIcon />
    </div>
  );

  return (
    <Modal
      footer={null}
      closeIcon={closeIcon}
      destroyOnClose
      {...props}
      zIndex={9999}
    >
      {logo && <div className={style.logo}>{logo}</div>}
      {children}
    </Modal>
  );
};
export default UIModal;
