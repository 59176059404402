import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { SupportedLocale } from "@/constants/locales";
import { en, zh } from "make-plural/plurals";
import { PluralCategory } from "make-plural/plurals";
import { ReactNode, useEffect } from "react";

type LocalePlural = {
  [key in SupportedLocale]: (
    n: number | string,
    ord?: boolean
  ) => PluralCategory;
};

const plurals: LocalePlural = {
  en: en,
  zh: zh,
};

export async function dynamicActivate(locale: SupportedLocale) {
  i18n.loadLocaleData(locale, { plurals: () => plurals[locale] });
  // There are no default messages in production; instead, bundle the default to save a network request:
  // see https://github.com/lingui/js-lingui/issues/388#issuecomment-497779030
  //   const catalog =
  //     locale === DEFAULT_LOCALE
  //       ? DEFAULT_CATALOG
  //       : await import(`${process.env.REACT_APP_LOCALES}/${locale}`);
  //   i18n.load(locale, catalog.messages);
  //   i18n.activate(locale);

  const { messages } = await import(`../locales/${locale}/messages`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}

interface ProviderProps {
  locale: SupportedLocale;
  forceRenderAfterLocaleChange?: boolean;
  onActivate?: (locale: SupportedLocale) => void;
  children: ReactNode;
}

export function Provider({
  locale,
  forceRenderAfterLocaleChange = true,
  onActivate,
  children,
}: ProviderProps) {
  useEffect(() => {
    dynamicActivate(locale)
      .then(() => onActivate?.(locale))
      .catch((error) => {
        console.error("Failed to activate locale", locale, error);
      });
  }, [locale, onActivate]);

  return (
    <I18nProvider
      forceRenderOnLocaleChange={forceRenderAfterLocaleChange}
      i18n={i18n}
    >
      {children}
    </I18nProvider>
  );
}
