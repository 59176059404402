import multicall from "@/state/multicall";
import { SkipFirst } from "@/types/tuple";
import useBlockNumber from "./useBlockNumber";
import useActiveWeb3React from "@/hooks/useActiveWeb3React";

export { NEVER_RELOAD } from "@uniswap/redux-multicall"; // re-export for convenience

type SkipFirstTwoParams<T extends (...args: any) => any> = SkipFirst<
  Parameters<T>,
  2
>;

export function useMultipleContractSingleData(
  ...args: SkipFirstTwoParams<
    typeof multicall.hooks.useMultipleContractSingleData
  >
) {
  const { chainId, latestBlock } = useCallContext();
  return multicall.hooks.useMultipleContractSingleData(
    chainId,
    latestBlock,
    ...args
  );
}

export function useSingleCallResult(
  ...args: SkipFirstTwoParams<typeof multicall.hooks.useSingleCallResult>
) {
  const { chainId, latestBlock } = useCallContext();
  return multicall.hooks.useSingleCallResult(chainId, latestBlock, ...args);
}

export function useSingleContractMultipleData(
  ...args: SkipFirstTwoParams<
    typeof multicall.hooks.useSingleContractMultipleData
  >
) {
  const { chainId, latestBlock } = useCallContext();
  return multicall.hooks.useSingleContractMultipleData(
    chainId,
    latestBlock,
    ...args
  );
}

function useCallContext() {
  const { chainId } = useActiveWeb3React();
  const latestBlock = useBlockNumber();
  return { chainId, latestBlock };
}
