import { parseUnits } from "@ethersproject/units";
import { Currency, CurrencyAmount, Fraction } from "@drxswap/sdk-core";
import JSBI from "jsbi";

/**
 * Parses a CurrencyAmount from the passed string.
 * Returns the CurrencyAmount, or undefined if parsing fails.
 */
export default function tryParseCurrencyAmount<T extends Currency>(
  value?: string,
  currency?: T
): CurrencyAmount<T> | undefined {
  if (!value || !currency) {
    return undefined;
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString();
    if (typedValueParsed !== "0") {
      return CurrencyAmount.fromRawAmount(
        currency,
        JSBI.BigInt(typedValueParsed)
      );
    }
  } catch (error) {
    // fails if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error);
  }
  return undefined;
}

export function tryParseFraction(value?: string, decimals?: string | number) {
  if (!value) {
    return undefined;
  }
  try {
    const typedValueParsed = parseUnits(value, decimals ?? 18).toString();
    return new Fraction(typedValueParsed, decimals).divide(
      JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(decimals ?? 18))
    );
  } catch (error) {
    // fails if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse fraction: "${value}"`, error);
  }
  return undefined;
}
