import { SupportedChainId } from "@/constants/chains";
export enum ExplorerDataType {
  TRANSACTION = "transaction",
  TOKEN = "token",
  ADDRESS = "address",
  BLOCK = "block",
}

const GATESCAN_PREFIXES: { [chainId: number]: string } = {
  [SupportedChainId.MAINNET]: "https://gatescan.org",
  [SupportedChainId.METEORA]: "https://gatescan.org/testnet",
};

export function getExplorerLink(
  chainId: number,
  data: string,
  type: ExplorerDataType
): string {
  const prefix = GATESCAN_PREFIXES[chainId] ?? "https://gatescan.org";

  switch (type) {
    case ExplorerDataType.TRANSACTION:
      return `${prefix}/tx/${data}`;

    case ExplorerDataType.TOKEN:
      return `${prefix}/token/${data}`;

    case ExplorerDataType.BLOCK:
      return `${prefix}/block/${data}`;

    case ExplorerDataType.ADDRESS:
      return `${prefix}/address/${data}`;
    default:
      return `${prefix}`;
  }
}
