export const SUPPORTED_LOCALES = [
  // order as they appear in the language dropdown
  "en",
  "zh",
];
export type SupportedLocale = typeof SUPPORTED_LOCALES[number];

// eslint-disable-next-line import/first
export const DEFAULT_LOCALE: SupportedLocale = "en";

// export const LOCALE_LABEL: { [locale in SupportedLocale]: string } = {
//   "en-US": "EN",
//   "zh-CN": "ZH",
// };

export const LOCALE_LABEL: { [locale in SupportedLocale]: string } = {
  en: "English",
  zh: "简体中文",
};
