import { Token } from "./token";

/**
 * Known WETH9 implementation addresses, used in our implementation of Ether#wrapped
 */
export const WETH9: { [chainId: number]: Token } = {
  85: new Token(
    85,
    "0x04Fc5a3BC25E37599e10DD8b837F1284e318ff23",
    18,
    "WGT",
    "Wrapped GateToken"
  ),
  86: new Token(
    86,
    "0x672f30407A71fa8737A3A14474ff37E09c7Fc44a",
    18,
    "WGT",
    "Wrapped GateToken"
  ),
};
