import { useEffect } from "react";

import { getConnection, injected } from "../connectors";
import { ConnectionType } from "@/constants/wallets";
import useTryActivation from "@/components/Modal/WalletModal/hooks/useTryActivation";
import { getFromLocalStorage, STORAGE_ITEMS } from "@/utils/storage";

const safeGetLocalStorageItem = () => {
  try {
    return getFromLocalStorage(
      STORAGE_ITEMS.CONNECTED_WALLET
    ) as ConnectionType;
  } catch (err: any) {
    // Ignore Local Storage Browser error
    // - NS_ERROR_FILE_CORRUPTED
    // - QuotaExceededError
    console.error(`Local Storage error: ${err?.message}`);

    return null;
  }
};

export function useEagerConnect() {
  const login = useTryActivation();

  useEffect(() => {
    const selectedWallet = safeGetLocalStorageItem();
    const connector = selectedWallet ? getConnection(selectedWallet) : null;
    if (selectedWallet && connector) {
      if (selectedWallet === ConnectionType.INJECTED) {
        injected.isAuthorized().then(() => login(selectedWallet));
      } else {
        login(selectedWallet);
      }
    }
  }, [login]);
}
