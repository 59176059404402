import { getConnection } from "@/connectors";
import { ConnectionType } from "@/constants/wallets";
import { removeLocalStorage, STORAGE_ITEMS } from "@/utils/storage";

import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { useEffect } from "react";
import useActiveWeb3React from "./useActiveWeb3React";

export const useAccountEventListener = () => {
  const { account, chainId, connector } = useActiveWeb3React();

  useEffect(() => {
    if (account && connector) {
      const handleUpdateEvent = () => {
        clearUserStates();
      };

      const handleDeactiveEvent = () => {
        clearUserStates(true);
      };

      connector.addListener("Web3ReactDeactivate", handleDeactiveEvent);
      connector.addListener("Web3ReactUpdate", handleUpdateEvent);

      return () => {
        connector.removeListener("Web3ReactDeactivate", handleDeactiveEvent);
        connector.removeListener("Web3ReactUpdate", handleUpdateEvent);
      };
    }
    return undefined;
  }, [account, chainId, connector]);
};

function clearUserStates(isDeactive = false) {
  if (window?.localStorage?.getItem("walletconnect")) {
    (
      getConnection(ConnectionType.WALLET_CONNECT) as WalletConnectConnector
    ).walletConnectProvider = undefined;
  }
  // Only clear localStorage when user disconnect,switch address no need clear it.
  if (isDeactive) {
    removeLocalStorage(STORAGE_ITEMS.CONNECTED_WALLET);
  }
}
