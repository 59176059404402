import useActiveWeb3React from "@/hooks/useActiveWeb3React";
import { removeLocalStorage, STORAGE_ITEMS } from "@/utils/storage";
import { useCallback } from "react";

export default function useTryDeactivation() {
  const { deactivate } = useActiveWeb3React();
  const tryDeactivation = useCallback(async () => {
    try {
      await deactivate();
      removeLocalStorage(STORAGE_ITEMS.CONNECTED_WALLET);
    } catch {}
  }, [deactivate]);
  return tryDeactivation;
}
