import { Switch } from "antd";
import style from "./style.module.scss";
import { SwitchChangeEventHandler } from "antd/lib/switch";
import { useState } from "react";
import { storeLocale } from "@/hooks/useActiveLocale";
import { useUserLocaleManager } from "@/applications/DEX/state/user/hooks";

export default function LanguageSwitcher() {
  const [, setUserLocale] = useUserLocaleManager();
  const defaultLocale = storeLocale();
  const [en, setEn] = useState(defaultLocale === "en");

  const onChange: SwitchChangeEventHandler = (checked) => {
    setEn(checked);
    if (checked) {
      setUserLocale("en");
    } else {
      setUserLocale("zh");
    }
  };
  return (
    <div className={style.languageSwitch}>
      <Switch className={style.switchBtn} checked={en} onChange={onChange} />
    </div>
  );
}
