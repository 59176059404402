import { ConnectionType } from "@/constants/wallets";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { ALL_SUPPORTED_CHAIN_IDS, CHAIN_RPC_MAP } from "../constants/chains";

export const injected = new InjectedConnector({
  supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
});

export const walletconnect = new WalletConnectConnector({
  supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
  rpc: CHAIN_RPC_MAP,
  qrcode: true,
});

export const getConnection = (c: ConnectionType) => {
  switch (c) {
    case ConnectionType.INJECTED:
      return injected;
    case ConnectionType.WALLET_CONNECT:
      return walletconnect;
  }
};
