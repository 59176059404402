import { createReducer } from "@reduxjs/toolkit";
import { fetchFarmContract, updatePool, updatePools } from "./actions";

interface FarmState {
  readonly contracts: {
    readonly current: {
      addresses: string[];
      version: string;
    };
    readonly requestId: string | null;
    readonly error: string | null;
  };
  readonly pools: {
    readonly [address: string]: {
      readonly symbol: string | null;
      readonly rewardPerBlock?: string;
      readonly totalDeposit: string;
      readonly staked: string;
      readonly earned: string;
      readonly rewardToken?: string;
      readonly stakeToken?: string;
    };
  };
}

export type FarmPoolState = FarmState["pools"][number];

const NEW_POOL_STATE: FarmPoolState = {
  symbol: null,
  rewardPerBlock: undefined,
  totalDeposit: "0",
  staked: "0",
  earned: "0",
  stakeToken: undefined,
  rewardToken: undefined,
};

const initialState: FarmState = {
  contracts: {
    current: {
      version: "",
      addresses: [],
    },
    requestId: null,
    error: null,
  },
  pools: {},
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(fetchFarmContract.pending, (state, { payload: { requestId } }) => {
      state.contracts.requestId = requestId;
      state.contracts.error = null;
    })
    .addCase(
      fetchFarmContract.fulfilled,
      (state, { payload: { requestId, farmContract } }) => {
        const { current, requestId: loadingRequestId } = state.contracts;
        if (current) {
          if (current.version === farmContract.version) return;
          if (loadingRequestId !== null && loadingRequestId !== requestId) {
            return;
          }
        }
        const onAdded = farmContract.contracts.filter(
          ({ address }) => !current.addresses.includes(address)
        );
        onAdded.forEach(({ address, symbol }) => {
          state.pools[address] = {
            ...NEW_POOL_STATE,
            symbol,
          };
        });
        state.contracts = {
          error: null,
          requestId: null,
          current: {
            version: farmContract.version,
            addresses: farmContract.contracts.map(({ address }) => address),
          },
        };
      }
    )
    .addCase(
      fetchFarmContract.rejected,
      (state, { payload: { requestId, error } }) => {
        if (state.contracts.requestId !== requestId) return;
        state.contracts.requestId = null;
        state.contracts.error = error;
      }
    )
    .addCase(updatePool, (state, { payload: { address, poolInfo } }) => {
      state.pools[address] = {
        ...state.pools[address],
        ...poolInfo,
      };
    })
    .addCase(updatePools, (state, { payload }) => {
      payload.forEach(({ address, pool }) => {
        state.pools[address] = {
          ...state.pools[address],
          ...pool,
        };
      });
    })
);
