import { getConnection } from "@/connectors";
import {
  BLOCK_EXPLORER_URLS,
  CHAIN_RPC_URLS,
  SupportedChainId,
  SupportedChainName,
} from "@/constants/chains";
import { ConnectionType } from "@/constants/wallets";
import usePrevious from "@/hooks/usePrevious";
import { useToConnecting, useToConnectType } from "@/state/application/hooks";
import { removeLocalStorage, STORAGE_ITEMS } from "@/utils/storage";
import { ExternalProvider } from "@ethersproject/providers";
import { t } from "@lingui/macro";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import {
  WalletConnectConnector,
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
} from "@web3-react/walletconnect-connector";
import { message, notification } from "antd";
import { useCallback, useEffect } from "react";

export default function useConnectError() {
  const { error, activate } = useWeb3React();
  const toConnectType = useToConnectType();

  const errorHandler = useCallback(
    async (error: Error, toConnectType: ConnectionType | null) => {
      const connector = toConnectType ? getConnection(toConnectType) : null;
      if (connector) {
        if (error instanceof UnsupportedChainIdError) {
          const provider = await connector.getProvider();
          const hasSetup = await setupNetwork(provider);
          if (hasSetup) {
            await activate(connector);
          }
        } else {
          removeLocalStorage(STORAGE_ITEMS.CONNECTED_WALLET);
          if (error instanceof NoEthereumProviderError) {
            notification.open({
              message: t`Wallet Not Found`,
              description:
                "Please install and initialize metamask wallet extension first, click here to install extension.",
              onClick() {
                window.open("https://metamask.io/", "_blank");
              },
            });
          } else if (
            error instanceof UserRejectedRequestErrorInjected ||
            error instanceof UserRejectedRequestErrorWalletConnect
          ) {
            if (connector instanceof WalletConnectConnector) {
              connector.walletConnectProvider = undefined;
            }
            notification.error({
              message: t`Authorization Error`,
              description: t`Please authorize to access your account`,
            });
          } else {
            notification.error({
              message: error.name,
              description: error.message,
            });
          }
        }
      }
    },
    [activate]
  );

  const toConnecting = useToConnecting();
  const prevError = usePrevious(error);
  useEffect(() => {
    (async () => {
      if (error && toConnecting && prevError !== error) {
        await errorHandler(error, toConnectType);
      }
    })();
  }, [error, errorHandler, toConnecting, toConnectType, prevError]);
}

export async function setupNetwork(externalProvider?: ExternalProvider) {
  const provider = externalProvider || window.ethereum;
  const chainId = SupportedChainId.MAINNET;
  if (provider) {
    try {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      });
      return true;
    } catch (switchError: any) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (
        switchError?.code === 4902 ||
        switchError?.message
          ?.toLowerCase()
          ?.startsWith("Unrecognized chain ID".toLowerCase())
      ) {
        try {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `0x${chainId.toString(16)}`,
                chainName: SupportedChainName[chainId],
                nativeCurrency: {
                  name: "GT",
                  symbol: "GT",
                  decimals: 18,
                },
                rpcUrls: CHAIN_RPC_URLS[chainId],
                blockExplorerUrls: BLOCK_EXPLORER_URLS[chainId],
              },
            ],
          });
          return true;
        } catch (addError) {
          // handle "add" error
          message.error(
            t`There's something wrong with adding ${SupportedChainName[chainId]}`
          );
          return false;
        }
      } else {
        // handle other "switch" errors
        if (
          switchError.message.toLowerCase().includes("provider is not ready")
        ) {
          message.error(t`Please connect with ethereum wallet`);
        } else {
          message.error(t`Network switch error: ${switchError.message}`);
        }
        return false;
      }
    }
  } else {
    return false;
  }
}
