import { ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { IPool, PoolAddress } from "../../types";

export const fetchAddressList: Readonly<{
  pending: ActionCreatorWithPayload<{ url: string; requestId: string }>;
  fulfilled: ActionCreatorWithPayload<{
    url: string;
    addresses: PoolAddress[];
    requestId: string;
  }>;
  rejected: ActionCreatorWithPayload<{
    url: string;
    errorMessage: string;
    requestId: string;
  }>;
}> = {
  pending: createAction("pool/fetchAddresses/pending"),
  fulfilled: createAction("pool/fetchAddresses/fulfilled"),
  rejected: createAction("pool/fetchAddresses/rejected"),
};

export const updateAddresses = createAction<{ addresses: PoolAddress[] }>(
  "pool/updateAddresses"
);

export const updatePoolList = createAction<{
  pools: {
    [address: string]: IPool;
  };
}>("pool/updatePoolList");

export const updatePoolDetail = createAction<{
  poolDetail: IPool;
}>("pool/updatePoolDetail");
