import useActiveWeb3React from "@/hooks/useActiveWeb3React";
import { getConnection } from "@/connectors";
import { ConnectionType } from "@/constants/wallets";
import { removeLocalStorage, STORAGE_ITEMS } from "@/utils/storage";
import { useCallback } from "react";
import { useToConnect } from "@/state/application/hooks";

export default function useTryActivation() {
  const { activate } = useActiveWeb3React();
  const setToConnect = useToConnect();

  return useCallback(
    async (connectionType: ConnectionType) => {
      const connector = getConnection(connectionType);

      if (typeof connector !== "function" && connector) {
        setToConnect({
          type: connectionType,
          connecting: true,
        });
        await activate(connector);
        setToConnect({
          connecting: false,
        });
      } else {
        removeLocalStorage(STORAGE_ITEMS.CONNECTED_WALLET);
      }
    },
    [activate, setToConnect]
  );
}
