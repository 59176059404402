import UIModal from "@/ui/Modal";
import style from "./style.module.scss";
import { ReactNode, useEffect } from "react";
import usePrevious from "@/hooks/usePrevious";
import { useWeb3React } from "@web3-react/core";
import { Title } from "@/ui/Typography/Title";
import UIAlign from "@/ui/Align";
import { useLessThanLaptop } from "@/utils/media";

const WalletModalBase: React.FunctionComponent<{
  title: string;
  subtitle?: string;
  visible: boolean;
  closeModal: () => void;
  onClosed?: () => void;
  children: ReactNode;
}> = ({ visible, closeModal, onClosed, title, subtitle, children }) => {
  const { account } = useWeb3React();
  const previousAccount = usePrevious(account);

  useEffect(() => {
    if (account !== previousAccount && visible) {
      closeModal();
    }
  }, [account, previousAccount, closeModal, visible]);

  const isLessThanLaptop = useLessThanLaptop();
  return (
    <UIModal
      visible={visible}
      width={isLessThanLaptop ? 335 : 570}
      onCancel={closeModal}
      destroyOnClose
      bodyStyle={{
        padding: isLessThanLaptop ? "24x 20px" : "40px",
      }}
      afterClose={onClosed}
    >
      <header>
        <Title level={isLessThanLaptop ? 5 : 2}>{title}</Title>
        <div className={style.subtitle}>{subtitle}</div>
      </header>
      <main>
        <UIAlign direction="column" gap={34} className={style.walletList}>
          {children}
        </UIAlign>
      </main>
    </UIModal>
  );
};
export default WalletModalBase;
