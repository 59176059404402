export enum WalletStatus {
  READY,
  PENDING,
  OFF,
}
export interface WalletInfo {
  connectionType: ConnectionType;
  wallet: string;
  network: string;
  icon: string;
  status: WalletStatus;
}

export enum ConnectionType {
  INJECTED = "INJECTED",
  WALLET_CONNECT = "WALLET_CONNECT",
}

export const BACKFILLABLE_WALLETS = [ConnectionType.INJECTED];
