import { ReactElement, ReactNode } from "react";
import styles from "./style.module.scss";
import cn, { Argument } from "classnames";

import * as React from "react";

interface IAlignProps {
  inline?: boolean;
  direction?: "row" | "column";
  align?: "center" | "stretch" | "end" | "start";
  justify?: "between" | "center" | "around" | "evenly" | "end";
  wrap?: boolean;
  children: ReactNode | ReactNode[];
  type?: ReactElement["type"];
  className?: Argument;
  [key: string]: ReactElement["props"];
}
export default function UIAlign({
  children,
  align,
  justify,
  direction,
  gap = 0,
  type = "div",
  wrap = false,
  inline = false,
  className,
  style,
  ...props
}: IAlignProps) {
  const element = {
    type,
  };
  return (
    <element.type
      className={cn(
        styles[inline ? `inline-flex` : "flex"],
        justify && styles[`justify-${justify}`],
        align && styles[`items-${align}`],
        direction && styles[`flex-${direction}`],
        wrap && styles[`flex-wrap`],
        className
      )}
      style={{
        ...style,
        gap,
      }}
      {...props}
    >
      {children}
    </element.type>
  );
}
