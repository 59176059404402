import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@/state/hooks";

import { AppState } from "../index";
import {
  addPopup,
  ApplicationModal,
  PopupContent,
  removePopup,
  setOpenModal,
  setToConnect,
} from "./reducer";

export function useToConnecting(): boolean {
  const toConnect = useAppSelector(
    (state: AppState) => state.application.toConnect
  );
  return toConnect.connecting;
}
export function useToConnectType(): AppState["application"]["toConnect"]["type"] {
  const toConnect = useAppSelector(
    (state: AppState) => state.application.toConnect
  );
  return toConnect.type;
}
export function useToConnect() {
  const dispatch = useAppDispatch();

  return useCallback(
    (connectionType) => {
      dispatch(setToConnect(connectionType));
    },
    [dispatch]
  );
}
export function useModalOpen(modal: ApplicationModal): boolean {
  const openModal = useAppSelector(
    (state: AppState) => state.application.openModal
  );
  return openModal === modal;
}

export function useToggleModal(modal: ApplicationModal): () => void {
  const open = useModalOpen(modal);
  const dispatch = useAppDispatch();
  return useCallback(
    () => dispatch(setOpenModal(open ? null : modal)),
    [dispatch, modal, open]
  );
}

export function useWalletModalToggle(): () => void {
  return useToggleModal(ApplicationModal.WALLET);
}

// returns a function that allows adding a popup
export function useAddPopup(): (
  content: PopupContent,
  key?: string,
  removeAfterMs?: number
) => void {
  const dispatch = useAppDispatch();

  return useCallback(
    (content: PopupContent, key?: string, removeAfterMs?: number) => {
      dispatch(
        addPopup({
          content,
          key,
          removeAfterMs: removeAfterMs ?? 30000,
        })
      );
    },
    [dispatch]
  );
}

// returns a function that allows removing a popup via its key
export function useRemovePopup(): (key: string) => void {
  const dispatch = useAppDispatch();
  return useCallback(
    (key: string) => {
      dispatch(removePopup({ key }));
    },
    [dispatch]
  );
}

// get the list of active popups
export function useActivePopups(): AppState["application"]["popupList"] {
  const list = useAppSelector((state: AppState) => state.application.popupList);
  return useMemo(() => list.filter((item) => item.show), [list]);
}
