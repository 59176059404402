import { Grid } from "antd";
const { useBreakpoint } = Grid;

export function useLessThanTablet() {
  const { md } = useBreakpoint();
  return !md;
}

export function useLessThanMobile() {
  const { sm } = useBreakpoint();
  return !sm;
}

export function useLessThanLaptop() {
  const { xl } = useBreakpoint();
  return !xl;
}
