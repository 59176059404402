export enum SupportedChainId {
  METEORA = 85,
  MAINNET = 86,
}

export const ALL_SUPPORTED_CHAIN_IDS =
  process.env.REACT_APP_ENV === "production"
    ? [SupportedChainId.MAINNET]
    : [SupportedChainId.MAINNET, SupportedChainId.METEORA];

export enum SupportedChainName {
  "GateChain" = 86,
  "Meteora" = 85,
}

export const DEFAULT_CHAIN_ID =
  process.env.REACT_APP_ENV === "production"
    ? SupportedChainId.MAINNET
    : SupportedChainId.METEORA;

export const CHAIN_RPC_URLS = {
  [SupportedChainId.MAINNET]: [
    "https://evm.gatenode.cc",
    "https://evm.gatenode.net",
    "https://evm-1.gatenode.cc",
  ],
  [SupportedChainId.METEORA]: ["https://meteora-evm.gatenode.cc"],
};

export const CHAIN_RPC_MAP = {
  [SupportedChainId.MAINNET]: "https://evm.gatenode.cc",
  [SupportedChainId.METEORA]: "https://meteora-evm.gatenode.cc",
};

export const BLOCK_EXPLORER_URLS = {
  [SupportedChainId.MAINNET]: ["https://gatescan.org/"],
  [SupportedChainId.METEORA]: ["https://gatescan.org/testnet/"],
};
