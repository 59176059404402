import LibUpdater from "./LibUpdater";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";

import { useAddPopup } from "../application/hooks";
import { checkedTransaction, finalizeTransaction } from "./actions";
import useActiveWeb3React from "@/hooks/useActiveWeb3React";

export default function Updater() {
  const { chainId } = useActiveWeb3React();
  const addPopup = useAddPopup();

  const dispatch = useAppDispatch();
  const onCheck = useCallback(
    ({ chainId, hash, blockNumber }) =>
      dispatch(checkedTransaction({ chainId, hash, blockNumber })),
    [dispatch]
  );
  const onReceipt = useCallback(
    ({ chainId, hash, receipt }) => {
      dispatch(
        finalizeTransaction({
          chainId,
          hash,
          receipt: {
            blockHash: receipt.blockHash,
            blockNumber: receipt.blockNumber,
            contractAddress: receipt.contractAddress,
            from: receipt.from,
            status: receipt.status,
            to: receipt.to,
            transactionHash: receipt.transactionHash,
            transactionIndex: receipt.transactionIndex,
          },
        })
      );
      addPopup(
        {
          txn: { hash },
        },
        hash
      );
    },
    [addPopup, dispatch]
  );

  const state = useAppSelector((state) => state.transactions);
  const pendingTransactions = useMemo(
    () => (chainId ? state[chainId] ?? {} : {}),
    [chainId, state]
  );

  return (
    <LibUpdater
      pendingTransactions={pendingTransactions}
      onCheck={onCheck}
      onReceipt={onReceipt}
    />
  );
}
