import { ReactElement, ReactNode } from "react";
import style from "./style.module.scss";
import cn, { Argument } from "classnames";

export function Title({
  level,
  children,
  className,
  inline,
  weight,
  elementType = "div",
  ...props
}: {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  children: ReactNode;
  className?: Argument;
  inline?: boolean;
  weight?: number;
  [key: string]: ReactElement["props"];
}) {
  const element = {
    type: elementType,
  };
  return (
    <element.type
      className={cn(
        style[`level-${level}`],
        style[`weight-${weight}`],
        {
          [style["inline-line-height"]]: inline,
        },
        className
      )}
      {...props}
    >
      {children}
    </element.type>
  );
}

export default Title;
