import useActiveWeb3React from "@/hooks/useActiveWeb3React";
import { useTransaction } from "@/state/transactions/hooks";

import { ReactComponent as CheckCircleIcon } from "@/assets/svg/check-circle.svg";
import { ReactComponent as AlertCircleIcon } from "@/assets/svg/alert-circle.svg";
import { TransactionSummary, TransactionTitle } from "./TransactionSummary";
import { ExplorerDataType, getExplorerLink } from "@/utils/getExplorerLink";
import { Text } from "@/ui/Typography/Text";
import UIAlign from "@/ui/Align";

export default function TransactionPopup({ hash }: { hash: string }) {
  const { chainId } = useActiveWeb3React();

  const tx = useTransaction(hash);

  if (!tx) return null;
  const success = Boolean(tx.receipt && tx.receipt.status === 1);

  return (
    <div>
      <Text
        type={success ? "success" : "danger"}
        size="md"
        style={{
          marginBottom: "8px",
        }}
        bold
      >
        <UIAlign gap={10} align="center">
          {success ? (
            <CheckCircleIcon width={20} />
          ) : (
            <AlertCircleIcon width={20} />
          )}
          <TransactionTitle info={tx.info} success={success} />
        </UIAlign>
      </Text>

      <UIAlign gap={10}>
        <div style={{ width: "20px" }}></div>
        <Text type="description">
          <TransactionSummary info={tx.info} success={success} />{" "}
          {chainId && (
            <Text
              type="primary"
              link
              elementType="a"
              href={getExplorerLink(
                chainId,
                hash,
                ExplorerDataType.TRANSACTION
              )}
              target="_blank"
            >
              View on GateScan
            </Text>
          )}
        </Text>
      </UIAlign>
    </div>
  );
}
