export enum STORAGE_ITEMS {
  "INVITE_CODE" = "inviteCode",
  "TX_HASH_SET" = "hashSet",
  "CONNECTED_WALLET" = "connectedWallet",
}
const PREFIX = "DRX_";
export function getFromLocalStorage(key: STORAGE_ITEMS) {
  try {
    return window.localStorage.getItem(`${PREFIX}${key}`);
  } catch (e) {
    console.error("Error Storage: ", e);
    return "";
  }
}

export function setToLocalStorage(key: STORAGE_ITEMS, value: any) {
  try {
    return window.localStorage.setItem(`${PREFIX}${key}`, value);
  } catch (e) {
    console.error("Error Storage: ", e);
  }
}

export function removeLocalStorage(key: STORAGE_ITEMS) {
  try {
    return window.localStorage.removeItem(`${PREFIX}${key}`);
  } catch (e) {
    console.error("Error Storage: ", e);
  }
}
