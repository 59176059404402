import { Token } from "@drxswap/sdk-core";
import { useMemo } from "react";
import useActiveWeb3React from "@/hooks/useActiveWeb3React";
import { DEFAULT_CHAIN_ID } from "../constants/swap";
import { DRx } from "../constants/tokens";

export default function useBaseCurrency(): Token {
  const { chainId } = useActiveWeb3React();
  return useMemo(
    () => (chainId && DRx[chainId]) || DRx[DEFAULT_CHAIN_ID],
    [chainId]
  );
}
