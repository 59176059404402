import { t } from "@lingui/macro";
import MetaMaskIcon from "@/assets/images/wallets/metamask.png";
import WalletConnectIcon from "@/assets/images/wallets/walletconnect.png";
import ComingSoonTag from "@/ui/ComingSoonTag";
import WalletModalBase from "./Base";
import { ConnectionType, WalletInfo, WalletStatus } from "@/constants/wallets";
import WalletModalOption from "./Option";
import { useCallback, useEffect, useState } from "react";
import useTryActivation from "./hooks/useTryActivation";
import useTryDeactivation from "./hooks/useTryDeactivation";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { setToLocalStorage, STORAGE_ITEMS } from "@/utils/storage";
import { getConnection } from "@/connectors";
import useActiveWeb3React from "@/hooks/useActiveWeb3React";
import { Button, Col, Row, Space } from "antd";
import { Text } from "@/ui/Typography";
import { setupNetwork } from "./hooks/useConnectError";

interface WalletModalProps {
  visible: boolean;
  closeModal: () => void;
  isConnected: boolean;
  isWrongNetwork: boolean;
}

const wallets: WalletInfo[] = [
  {
    wallet: t`MetaMask`,
    network: t`GateChain`,
    icon: MetaMaskIcon,
    status: WalletStatus.READY,
    connectionType: ConnectionType.INJECTED,
  },
  {
    wallet: t`Wallet Connect`,
    network: t`GateChain`,
    icon: WalletConnectIcon,
    status: WalletStatus.READY,
    connectionType: ConnectionType.WALLET_CONNECT,
  },
];

const findWallet = (connector?: AbstractConnector) => {
  if (!connector) {
    return null;
  }
  return wallets.find(
    ({ connectionType }) => getConnection(connectionType) === connector
  );
};

const WalletModal: React.FunctionComponent<WalletModalProps> = ({
  visible,
  closeModal,
  isConnected,
  isWrongNetwork,
}) => {
  const [isConnectedMode, setIsConnectedMode] = useState(isConnected);
  const [isModalClosed, setIsModalClosed] = useState(true);

  useEffect(() => {
    if (isModalClosed) {
      setIsConnectedMode(isConnected);
    }
  }, [isConnected, isModalClosed]);

  useEffect(() => {
    if (visible) {
      setIsModalClosed(false);
    }
  }, [visible]);

  const title = isWrongNetwork
    ? {
        title: "",
        subtitle: "",
      }
    : isConnectedMode
    ? {
        title: t`Disconnect Wallet`,
        subtitle: "",
      }
    : {
        title: t`Connect Wallet`,
        subtitle: "",
      };
  return (
    <WalletModalBase
      visible={visible}
      closeModal={closeModal}
      onClosed={() => {
        setIsModalClosed(true);
      }}
      title={title.title}
      subtitle={title.subtitle}
    >
      {isWrongNetwork ? (
        <SwitchNetworkContent />
      ) : isConnectedMode ? (
        <WalletDisconnectContent />
      ) : (
        <WalletConnectContent />
      )}
    </WalletModalBase>
  );
};

function SwitchNetworkContent() {
  const { connector, activate } = useActiveWeb3React();
  const logout = useTryDeactivation();

  const switchNetwork = useCallback(async () => {
    if (connector) {
      const provider = await connector.getProvider();
      const hasSetup = await setupNetwork(provider);
      if (hasSetup) {
        await activate(connector);
      }
    }
  }, [activate, connector]);

  return (
    <Space direction="vertical" size={24} align="center">
      <Text
        size="lg"
        style={{
          textAlign: "center",
        }}
      >{t`You’re connected to the wrong network.`}</Text>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          {
            <Button
              onClick={switchNetwork}
              size="large"
              style={{
                width: "100%",
              }}
            >{t`Switch Network`}</Button>
          }
        </Col>
        <Col span={24}>
          <Button
            type="primary"
            ghost
            onClick={logout}
            size="large"
            style={{
              width: "100%",
            }}
          >{t`Disconnect Wallet`}</Button>
        </Col>
      </Row>
    </Space>
  );
}

function WalletConnectContent() {
  const login = useTryActivation();

  return (
    <>
      {wallets.map(({ wallet, network, icon, connectionType, status }) => (
        <WalletModalOption
          key={wallet}
          title={wallet}
          subtitle={network}
          icon={icon}
          onClick={async () => {
            await login(connectionType);
            setToLocalStorage(STORAGE_ITEMS.CONNECTED_WALLET, connectionType);
          }}
          disabled={status !== WalletStatus.READY}
        >
          {status === WalletStatus.PENDING && <ComingSoonTag />}
        </WalletModalOption>
      ))}
    </>
  );
}

function WalletDisconnectContent() {
  const logout = useTryDeactivation();
  const { connector } = useActiveWeb3React();
  const wallet = findWallet(connector);

  if (!wallet) {
    return null;
  } else {
    return (
      <WalletModalOption
        key={wallet.wallet}
        title={wallet.wallet}
        subtitle={wallet.network}
        icon={wallet.icon}
        onClick={() => logout()}
      ></WalletModalOption>
    );
  }
}

export default WalletModal;
