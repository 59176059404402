import { NativeCurrency } from "@drxswap/sdk-core";
import { nativeOnChain } from "../constants/tokens";
import { useMemo } from "react";
import useActiveWeb3React from "@/hooks/useActiveWeb3React";
import { DEFAULT_CHAIN_ID } from "../constants/swap";

export default function useNativeCurrency(): NativeCurrency {
  const { chainId } = useActiveWeb3React();
  return useMemo(
    () =>
      chainId
        ? nativeOnChain(chainId)
        : // display mainnet when not connected
          nativeOnChain(DEFAULT_CHAIN_ID),
    [chainId]
  );
}
