import { Trans } from "@lingui/macro";
import { Fraction, TradeType } from "@drxswap/sdk-core";
import JSBI from "jsbi";

import {
  AddLiquidityTransactionInfo,
  ApproveTransactionInfo,
  ExactInputSwapTransactionInfo,
  ExactOutputSwapTransactionInfo,
  RemoveLiquidityTransactionInfo,
  TransactionInfo,
  TransactionType,
  WrapTransactionInfo,
  PoolTransactionInfo,
  ExitTransactionInfo,
} from "@/state/transactions/actions";
import useCurrency, { useToken } from "@/applications/DEX/hooks/useCurrency";
import { nativeOnChain } from "@/applications/DEX/constants/tokens";
import { Text } from "@/ui/Typography/Text";

function formatAmount(
  amountRaw: string,
  decimals: number,
  sigFigs: number
): string {
  return new Fraction(
    amountRaw,
    JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(decimals))
  ).toSignificant(sigFigs);
}

function FormattedCurrencyAmount({
  rawAmount,
  symbol,
  decimals,
  sigFigs,
}: {
  rawAmount: string;
  symbol: string;
  decimals: number;
  sigFigs: number;
}) {
  return (
    <Text type="normal" elementType="span">
      {formatAmount(rawAmount, decimals, sigFigs)} {symbol}
    </Text>
  );
}

function FormattedCurrency({ symbol }: { symbol?: string }) {
  return (
    <Text type="normal" elementType="span">
      {symbol ?? "GT"}
    </Text>
  );
}

function FormattedCurrencyAmountManaged({
  rawAmount,
  currencyId,
  sigFigs = 6,
  symbol,
}: {
  rawAmount: string;
  currencyId: string;
  sigFigs: number;
  symbol?: string;
}) {
  const currency = useCurrency(currencyId);
  return currency ? (
    <FormattedCurrencyAmount
      rawAmount={rawAmount}
      decimals={currency.decimals}
      sigFigs={sigFigs}
      symbol={symbol ?? currency.symbol ?? "???"}
    />
  ) : null;
}

function ApprovalTitle({ success }: { success: boolean }) {
  return success ? (
    <Trans>Approve Completed!</Trans>
  ) : (
    <Trans>Failed to Approve</Trans>
  );
}
function ApprovalSummary({ info }: { info: ApproveTransactionInfo }) {
  const token = useToken(info.tokenAddress);

  return (
    <>
      <Trans>Approve {token?.symbol}</Trans>
      <br />
    </>
  );
}

function WrapTitle({
  success,
  info: { unwrapped },
}: {
  info: WrapTransactionInfo;
  success: boolean;
}) {
  if (unwrapped) {
    return success ? (
      <Trans>Unwrap Completed!</Trans>
    ) : (
      <Trans>Unwrap Failed</Trans>
    );
  } else {
    return success ? (
      <Trans>Wrap Completed!</Trans>
    ) : (
      <Trans>Wrap Failed</Trans>
    );
  }
}
function WrapSummary({
  info: { chainId, currencyAmountRaw, unwrapped },
  success,
}: {
  info: WrapTransactionInfo;
  success: boolean;
}) {
  const native = chainId ? nativeOnChain(chainId) : undefined;

  if (unwrapped) {
    if (success) {
      return (
        <>
          <Trans>
            You unwrap exactly{" "}
            <FormattedCurrencyAmount
              rawAmount={currencyAmountRaw}
              symbol={native?.symbol ?? "GT"}
              decimals={18}
              sigFigs={6}
            />{" "}
            to <FormattedCurrency symbol={native?.wrapped?.symbol} />
          </Trans>
          <br />
        </>
      );
    } else {
      return (
        <Trans>
          Your unwrap of{" "}
          <FormattedCurrencyAmount
            rawAmount={currencyAmountRaw}
            symbol={native?.symbol ?? "GT"}
            decimals={18}
            sigFigs={6}
          />{" "}
          to <FormattedCurrency symbol={native?.wrapped?.symbol} /> failed,
          please retry.
        </Trans>
      );
    }
  } else {
    if (success) {
      return (
        <>
          <Trans>
            You wrap exactly{" "}
            <FormattedCurrencyAmount
              rawAmount={currencyAmountRaw}
              symbol={native?.symbol ?? "GT"}
              decimals={18}
              sigFigs={6}
            />{" "}
            to <FormattedCurrency symbol={native?.wrapped?.symbol} />
          </Trans>
          <br />
        </>
      );
    } else {
      return (
        <Trans>
          Your swap of{" "}
          <FormattedCurrencyAmount
            rawAmount={currencyAmountRaw}
            symbol={native?.symbol ?? "GT"}
            decimals={18}
            sigFigs={6}
          />{" "}
          to <FormattedCurrency symbol={native?.wrapped?.symbol} /> failed,
          please retry.
        </Trans>
      );
    }
  }
}

function RemoveLiquidityTitle({ success }: { success: boolean }) {
  return success ? (
    <Trans>Remove Completed!</Trans>
  ) : (
    <Trans>Failed to Remove Liquidity</Trans>
  );
}
function RemoveLiquiditySummary({
  info: {
    baseCurrencyId,
    quoteCurrencyId,
    expectedAmountBaseRaw,
    expectedAmountQuoteRaw,
  },
  success,
}: {
  info: RemoveLiquidityTransactionInfo;
  success: boolean;
}) {
  if (success) {
    return (
      <>
        <Trans>
          Remove{" "}
          <FormattedCurrencyAmountManaged
            rawAmount={expectedAmountBaseRaw}
            currencyId={baseCurrencyId}
            sigFigs={3}
          />{" "}
          and{" "}
          <FormattedCurrencyAmountManaged
            rawAmount={expectedAmountQuoteRaw}
            currencyId={quoteCurrencyId}
            sigFigs={3}
          />
        </Trans>
        <br />
      </>
    );
  } else {
    return (
      <Trans>
        Failed to remove liquidity of{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={expectedAmountBaseRaw}
          currencyId={baseCurrencyId}
          sigFigs={3}
        />{" "}
        and{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={expectedAmountQuoteRaw}
          currencyId={quoteCurrencyId}
          sigFigs={3}
        />
        , please retry.
      </Trans>
    );
  }
}

function AddLiquidityTitle({ success }: { success: boolean }) {
  return success ? (
    <Trans>Liquidity Added</Trans>
  ) : (
    <Trans>Failed to Add Liquidity</Trans>
  );
}
function AddLiquiditySummary({
  info: {
    quoteCurrencyId,
    expectedAmountBaseRaw,
    expectedAmountQuoteRaw,
    baseCurrencyId,
  },
  success,
}: {
  info: AddLiquidityTransactionInfo;
  success: boolean;
}) {
  if (success) {
    return (
      <Trans>
        You've just added liquidity of{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={expectedAmountBaseRaw}
          currencyId={baseCurrencyId}
          sigFigs={3}
        />{" "}
        and{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={expectedAmountQuoteRaw}
          currencyId={quoteCurrencyId}
          sigFigs={3}
        />
        .{" "}
      </Trans>
    );
  } else {
    return (
      <Trans>
        Failed to add liquidity of{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={expectedAmountBaseRaw}
          currencyId={baseCurrencyId}
          sigFigs={3}
        />{" "}
        and{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={expectedAmountQuoteRaw}
          currencyId={quoteCurrencyId}
          sigFigs={3}
        />
        , please retry.
      </Trans>
    );
  }
}

function SwapTitle({ success }: { success: boolean }) {
  return success ? <Trans>Swap Completed!</Trans> : <Trans>Swap Failed</Trans>;
}
function SwapSummary({
  info,
  success,
}: {
  info: ExactInputSwapTransactionInfo | ExactOutputSwapTransactionInfo;
  success: boolean;
}) {
  if (info.tradeType === TradeType.EXACT_INPUT) {
    if (success) {
      return (
        <>
          <Trans>
            You swap exactly{" "}
            <FormattedCurrencyAmountManaged
              rawAmount={info.inputCurrencyAmountRaw}
              currencyId={info.inputCurrencyId}
              sigFigs={6}
            />{" "}
            for{" "}
            <FormattedCurrencyAmountManaged
              rawAmount={info.expectedOutputCurrencyAmountRaw}
              currencyId={info.outputCurrencyId}
              sigFigs={6}
            />
          </Trans>
          <br />
        </>
      );
    } else {
      return (
        <Trans>
          Your swap of{" "}
          <FormattedCurrencyAmountManaged
            rawAmount={info.inputCurrencyAmountRaw}
            currencyId={info.inputCurrencyId}
            sigFigs={6}
          />{" "}
          for{" "}
          <FormattedCurrencyAmountManaged
            rawAmount={info.expectedOutputCurrencyAmountRaw}
            currencyId={info.outputCurrencyId}
            sigFigs={6}
          />{" "}
          failed, please retry.
        </Trans>
      );
    }
  } else {
    if (success) {
      return (
        <>
          <Trans>
            You swap exactly{" "}
            <FormattedCurrencyAmountManaged
              rawAmount={info.expectedInputCurrencyAmountRaw}
              currencyId={info.inputCurrencyId}
              sigFigs={6}
            />{" "}
            for exactly{" "}
            <FormattedCurrencyAmountManaged
              rawAmount={info.outputCurrencyAmountRaw}
              currencyId={info.outputCurrencyId}
              sigFigs={6}
            />
          </Trans>
          <br />
        </>
      );
    } else {
      return (
        <Trans>
          Your swap of{" "}
          <FormattedCurrencyAmountManaged
            rawAmount={info.expectedInputCurrencyAmountRaw}
            currencyId={info.inputCurrencyId}
            sigFigs={6}
          />{" "}
          for{" "}
          <FormattedCurrencyAmountManaged
            rawAmount={info.outputCurrencyAmountRaw}
            currencyId={info.outputCurrencyId}
            sigFigs={6}
          />{" "}
          failed, please retry.
        </Trans>
      );
    }
  }
}

function StakeTitle({ success }: { success: boolean }) {
  return success ? <Trans>Token Staked</Trans> : <Trans>Failed to Stake</Trans>;
}
function StakeSummary({
  info: { currencyAmountRaw, currencyId, symbol },
  success,
}: {
  info: PoolTransactionInfo;
  success: boolean;
}) {
  if (success) {
    return (
      <>
        <Trans>
          You just staked{" "}
          <FormattedCurrencyAmountManaged
            rawAmount={currencyAmountRaw}
            currencyId={currencyId}
            symbol={symbol}
            sigFigs={6}
          />
        </Trans>
        <br />
      </>
    );
  } else {
    return (
      <Trans>
        Failed to stake{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={currencyAmountRaw}
          currencyId={currencyId}
          sigFigs={6}
          symbol={symbol}
        />
        , please retry.
      </Trans>
    );
  }
}

function UnStakeTitle({ success }: { success: boolean }) {
  return success ? (
    <Trans>Token Unstaked</Trans>
  ) : (
    <Trans>Failed to Unstake</Trans>
  );
}
function UnStakeSummary({
  info: { currencyAmountRaw, currencyId, symbol },
  success,
}: {
  info: PoolTransactionInfo;
  success: boolean;
}) {
  if (success) {
    return (
      <>
        <Trans>
          You just unstaked{" "}
          <FormattedCurrencyAmountManaged
            rawAmount={currencyAmountRaw}
            currencyId={currencyId}
            sigFigs={6}
            symbol={symbol}
          />
        </Trans>
        <br />
      </>
    );
  } else {
    return (
      <Trans>
        Failed to unstake{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={currencyAmountRaw}
          currencyId={currencyId}
          sigFigs={6}
          symbol={symbol}
        />
        , please retry.
      </Trans>
    );
  }
}

function RewardClaimTitle({ success }: { success: boolean }) {
  return success ? (
    <Trans>Reward Claimed</Trans>
  ) : (
    <Trans>Failed to Claim</Trans>
  );
}
function RewardClaimSummary({
  info: { currencyAmountRaw, currencyId, symbol },
  success,
}: {
  info: PoolTransactionInfo;
  success: boolean;
}) {
  if (success) {
    return (
      <>
        <Trans>
          You just claimed{" "}
          <FormattedCurrencyAmountManaged
            rawAmount={currencyAmountRaw}
            currencyId={currencyId}
            sigFigs={6}
            symbol={symbol}
          />
        </Trans>
        <br />
      </>
    );
  } else {
    return (
      <Trans>
        Failed to claim{" "}
        <FormattedCurrencyAmountManaged
          rawAmount={currencyAmountRaw}
          currencyId={currencyId}
          sigFigs={6}
          symbol={symbol}
        />
        , please retry.
      </Trans>
    );
  }
}

function ExitTitle({ success }: { success: boolean }) {
  return success ? (
    <Trans>Token Unstaked and Reward Claimed</Trans>
  ) : (
    <Trans>Failed to Unstaked and Claim</Trans>
  );
}
function ExitSummary({
  info: {
    currencyAmountRaw,
    currencyId,
    rewardAmountRaw,
    rewardCurrencyId,
    symbol,
  },
  success,
}: {
  info: ExitTransactionInfo;
  success: boolean;
}) {
  if (success) {
    return (
      <>
        <Trans>
          You just unstaked{" "}
          <FormattedCurrencyAmountManaged
            rawAmount={currencyAmountRaw}
            currencyId={currencyId}
            sigFigs={6}
            symbol={symbol}
          />{" "}
          and claimed{" "}
          <FormattedCurrencyAmountManaged
            rawAmount={rewardAmountRaw}
            currencyId={rewardCurrencyId}
            sigFigs={6}
          />
        </Trans>
        <br />
      </>
    );
  } else {
    return (
      <>
        <Trans>
          Failed to unstaked{" "}
          <FormattedCurrencyAmountManaged
            rawAmount={currencyAmountRaw}
            currencyId={currencyId}
            sigFigs={6}
            symbol={symbol}
          />{" "}
          and claimed{" "}
          <FormattedCurrencyAmountManaged
            rawAmount={rewardAmountRaw}
            currencyId={rewardCurrencyId}
            sigFigs={6}
          />
          , please retry.
        </Trans>
      </>
    );
  }
}

export function TransactionSummary({
  info,
  success,
}: {
  info: TransactionInfo;
  success: boolean;
}) {
  switch (info.type) {
    case TransactionType.ADD_LIQUIDITY:
      return <AddLiquiditySummary info={info} success={success} />;
    case TransactionType.SWAP:
      return <SwapSummary info={info} success={success} />;
    case TransactionType.APPROVAL:
      return <ApprovalSummary info={info} />;
    case TransactionType.WRAP:
      return <WrapSummary info={info} success={success} />;
    case TransactionType.REMOVE_LIQUIDITY:
      return <RemoveLiquiditySummary info={info} success={success} />;
    case TransactionType.STAKE:
      return <StakeSummary info={info} success={success} />;
    case TransactionType.UNSTAKE:
      return <UnStakeSummary info={info} success={success} />;
    case TransactionType.CLAIM_REWARD:
      return <RewardClaimSummary info={info} success={success} />;
    case TransactionType.EXIT:
      return <ExitSummary info={info} success={success} />;
    default:
      return null;
  }
}

export function TransactionTitle({
  info,
  success,
}: {
  info: TransactionInfo;
  success: boolean;
}) {
  switch (info.type) {
    case TransactionType.ADD_LIQUIDITY:
      return <AddLiquidityTitle success={success} />;
    case TransactionType.SWAP:
      return <SwapTitle success={success} />;
    case TransactionType.APPROVAL:
      return <ApprovalTitle success={success} />;
    case TransactionType.WRAP:
      return <WrapTitle info={info} success={success} />;
    case TransactionType.REMOVE_LIQUIDITY:
      return <RemoveLiquidityTitle success={success} />;
    case TransactionType.STAKE:
      return <StakeTitle success={success} />;
    case TransactionType.UNSTAKE:
      return <UnStakeTitle success={success} />;
    case TransactionType.CLAIM_REWARD:
      return <RewardClaimTitle success={success} />;
    case TransactionType.EXIT:
      return <ExitTitle success={success} />;
    default:
      return null;
  }
}
