import { SupportedChainId } from "@/constants/chains";
import getS3Url from "@/utils/getS3Url";

export const MAINNET_LIST = `${getS3Url(
  SupportedChainId.MAINNET
)}/token_list.json`;
export const METEORA_LIST = `${getS3Url(
  SupportedChainId.METEORA
)}/token_list.json`;

export const UNSUPPORTED_LIST_URLS: string[] = [];

// this is the default list of lists that are exposed to users
// lower index == higher priority for token import
const DEFAULT_LIST_OF_LISTS_TO_DISPLAY: string[] = [MAINNET_LIST, METEORA_LIST];

export const DEFAULT_LIST_OF_LISTS: string[] = [
  ...DEFAULT_LIST_OF_LISTS_TO_DISPLAY,
  ...UNSUPPORTED_LIST_URLS, // need to load dynamic unsupported tokens as well
];

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [MAINNET_LIST];
