import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { updateVersion } from "./global/actions";
import lists from "@dex/state/lists/reducer";
import swap from "@dex/state/swap/reducer";
import user from "@dex/state/user/reducer";
import mint from "@dex/state/mint/reducer";
import burn from "@dex/state/burn/reducer";
import application from "./application/reducer";
import transactions from "./transactions/reducer";
import multicall from "./multicall";
import pool from "@pool/state/lists/reducer";
import farm from "@farm/state/farm/reducer";
import { load, save } from "redux-localstorage-simple";

const PERSISTED_KEYS: string[] = ["user", "transactions", "lists"];

const store = configureStore({
  reducer: {
    transactions,
    application,
    multicall: multicall.reducer,
    lists,
    swap,
    burn,
    user,
    mint,
    pool,
    farm,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true }).concat(
      save({ states: PERSISTED_KEYS, debounce: 1000 })
    ),
  preloadedState: load({
    states: PERSISTED_KEYS,
    disableWarnings: process.env.NODE_ENV === "test",
  }),
});

export default store;

store.dispatch(updateVersion());
setupListeners(store.dispatch);

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
