import { createSlice, nanoid } from "@reduxjs/toolkit";
import { SupportedChainId } from "@/constants/chains";
import { ConnectionType } from "@/constants/wallets";

export enum ApplicationModal {
  WALLET,
}

export type PopupContent =
  | {
      txn: {
        hash: string;
      };
    }
  | {
      failedSwitchNetwork: SupportedChainId;
    };

type PopupList = Array<{
  key: string;
  show: boolean;
  content: PopupContent;
  removeAfterMs: number | null;
}>;

export interface ApplicationState {
  readonly openModal: ApplicationModal | null;
  readonly popupList: PopupList;
  readonly toConnect: {
    connecting: boolean;
    type: ConnectionType | null;
  };
}

const initialState: ApplicationState = {
  openModal: null,
  popupList: [],
  toConnect: {
    connecting: false,
    type: null,
  },
};

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setToConnect(state, { payload: { type, connecting } }) {
      state.toConnect = {
        type: type ?? null,
        connecting: connecting,
      };
    },
    setOpenModal(state, action) {
      state.openModal = action.payload;
    },
    addPopup(state, { payload: { content, key, removeAfterMs } }) {
      state.popupList = (
        key
          ? state.popupList.filter((popup) => popup.key !== key)
          : state.popupList
      ).concat([
        {
          key: key || nanoid(),
          show: true,
          content,
          removeAfterMs,
        },
      ]);
    },
    removePopup(state, { payload: { key } }) {
      state.popupList.forEach((p) => {
        if (p.key === key) {
          p.show = false;
        }
      });
    },
  },
});

export const { setToConnect, setOpenModal, addPopup, removePopup } =
  applicationSlice.actions;
export default applicationSlice.reducer;
