import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "../components/Layout";
import { Suspense } from "react";
import React from "react";
import { Row, Spin } from "antd";
import Popups from "@/components/Popups";

const HomePage = React.lazy(() => import("./HomePage"));
const Pool = React.lazy(() => import("./Pool"));
const Farm = React.lazy(() => import("./Farm"));
const Swap = React.lazy(() => import("./Swap"));
const IGO = React.lazy(() => import("./IGO"));

function App() {
  return (
    <>
      <Popups />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="/home" replace />} />
          <Route
            path="home"
            element={
              <Suspense fallback={<PageLoading />}>
                <HomePage />
              </Suspense>
            }
          ></Route>
          <Route
            path="pool/*"
            element={
              <Suspense fallback={<PageLoading />}>
                <Pool />
              </Suspense>
            }
          ></Route>
          <Route
            path="farm/*"
            element={
              <Suspense fallback={<PageLoading />}>
                <Farm />
              </Suspense>
            }
          ></Route>
          <Route
            path="swap/*"
            element={
              <Suspense fallback={<PageLoading />}>
                <Swap />
              </Suspense>
            }
          ></Route>
          <Route
            path="ido/*"
            element={
              <Suspense fallback={<PageLoading />}>
                <IGO />
              </Suspense>
            }
          ></Route>
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
}

export default App;

function PageLoading() {
  return (
    <Row align="middle" justify="center">
      <Spin size="large" />
    </Row>
  );
}
