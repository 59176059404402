import { FACTORY_ADDRESS_MAP as V2_FACTORY_ADDRESS_MAP } from "@drxswap/v2-sdk";
import { AddressMap } from "@/utils/constructSameAddresses";
import { SupportedChainId } from "@/constants/chains";

export const V2_FACTORY_ADDRESSES: AddressMap = {
  ...V2_FACTORY_ADDRESS_MAP,
};
export const V2_ROUTER_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: "0xc4e233bCA4792f70dA5B3d42f0870862eA38B4E0",
  [SupportedChainId.METEORA]: "0x625ed0Ef744D8480789f4b0996a153c529417583",
};
