import { ConnectionType } from "@/constants/wallets";
import usePrevious from "@/hooks/usePrevious";
import { setToLocalStorage, STORAGE_ITEMS } from "@/utils/storage";
import { useEffect } from "react";
import useTryActivation from "../hooks/useTryActivation";
import useTryDeactivation from "../hooks/useTryDeactivation";

export default function WalletConnectForDAppBrowser({
  trigger,
  isConnectedMode,
  onActionDone,
}: {
  trigger: boolean;
  isConnectedMode: boolean;
  onActionDone?: () => void;
}) {
  const prevTrigger = usePrevious(trigger);
  const login = useTryActivation();
  const logout = useTryDeactivation();

  useEffect(() => {
    if (!prevTrigger && trigger) {
      if (isConnectedMode) {
        logout();
      } else {
        login(ConnectionType.INJECTED);
        setToLocalStorage(
          STORAGE_ITEMS.CONNECTED_WALLET,
          ConnectionType.INJECTED
        );
      }
      onActionDone && onActionDone();
    }
  }, [isConnectedMode, login, logout, onActionDone, prevTrigger, trigger]);
  return null;
}
