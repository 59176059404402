import { getContract } from "@/utils/web3";
import { Contract } from "@ethersproject/contracts";
import { useWeb3React } from "@web3-react/core";
import { useMemo } from "react";
import ERC20_ABI from "@/abis/erc20.json";
import ERC20_BYTES32_ABI from "@/abis/erc20_bytes32.json";
import ERC721_ABI from "@/abis/erc721.json";
import EIP_2612 from "@/abis/eip_2612.json";
import { Erc721, Erc20 } from "@/abis/types";
import { MULTICALL_ADDRESS } from "@/constants/addresses";
import DRXswapInterfaceMulticallJson from "@/abis/multicall.json";
import { Multicall } from "@/abis/types";
const { abi: MulticallABI } = DRXswapInterfaceMulticallJson;

export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { library, account, chainId } = useWeb3React();
  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !library || !chainId) return null;
    let address: string | undefined;
    if (typeof addressOrAddressMap === "string") address = addressOrAddressMap;
    else address = addressOrAddressMap[chainId];
    if (!address) return null;
    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [
    addressOrAddressMap,
    ABI,
    library,
    chainId,
    withSignerIfPossible,
    account,
  ]) as T;
}

export function useContractMap<T extends Contract = Contract>(
  addressMap: Record<string, string>,
  ABI: any,
  withSignerIfPossible = true
): Record<string, T> | null {
  const { library, account } = useWeb3React();
  return useMemo(() => {
    if (!addressMap || !ABI || !library) return null;
    const contracts = Object.entries(addressMap).map(([key, address]) => {
      try {
        return [
          key,
          getContract(
            address,
            ABI,
            library,
            withSignerIfPossible && account ? account : undefined
          ),
        ];
      } catch (error) {
        console.error("Failed to get contract", error);
        return [key, null];
      }
    });

    return Object.fromEntries(contracts);
  }, [ABI, account, addressMap, library, withSignerIfPossible]) as T;
}

export function useERC20TokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean
) {
  return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible);
}

export function useERC20TokenContractMap(
  tokenAddressMap: Record<string, string>,
  withSignerIfPossible?: boolean
) {
  return useContractMap<Erc20>(
    tokenAddressMap,
    ERC20_ABI,
    withSignerIfPossible
  );
}

export function useBytes32TokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean
): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible);
}

export function useEIP2612Contract(tokenAddress?: string): Contract | null {
  return useContract(tokenAddress, EIP_2612, false);
}

export function useERC721Contract(nftAddress?: string) {
  return useContract<Erc721>(nftAddress, ERC721_ABI, false);
}

export function useInterfaceMulticall() {
  return useContract<Multicall>(
    MULTICALL_ADDRESS,
    MulticallABI,
    false
  ) as Multicall;
}
