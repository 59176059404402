import {
  DEFAULT_LOCALE,
  // SUPPORTED_LOCALES,
  SupportedLocale,
} from "@/constants/locales";
import { useMemo } from "react";
import store from "@/state";
import { useUserLocale } from "@/applications/DEX/state/user/hooks";

// /**
//  * Given a locale string (e.g. from user agent), return the best match for corresponding SupportedLocale
//  * @param maybeSupportedLocale the fuzzy locale identifier
//  */
// function parseLocale(
//   maybeSupportedLocale: unknown
// ): SupportedLocale | undefined {
//   if (typeof maybeSupportedLocale !== "string") return undefined;
//   const lowerMaybeSupportedLocale = maybeSupportedLocale.toLowerCase();
//   return SUPPORTED_LOCALES.find(
//     (locale) =>
//       locale.toLowerCase() === lowerMaybeSupportedLocale ||
//       locale.split("-")[0] === lowerMaybeSupportedLocale
//   );
// }

export function storeLocale(): SupportedLocale | undefined {
  return store.getState().user.userLocale ?? undefined;
}

export const initialLocale = DEFAULT_LOCALE;

/**
 * Returns the currently active locale, from a combination of user agent, query string, and user settings stored in redux
 * Stores the query string locale in redux (if set) to persist across sessions
 */
export function useActiveLocale(): SupportedLocale {
  const userLocale = useUserLocale();
  return useMemo(() => userLocale ?? DEFAULT_LOCALE, [userLocale]);
}
