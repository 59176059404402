import { Percent, Token } from "@drxswap/sdk-core";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import {
  SerializedToken,
  updateUserClientSideRouter,
  updateUserDeadline,
  updateUserExpertMode,
  removeSerializedToken,
  updateUserSlippageTolerance,
  addSerializedToken,
  updateUserLocale,
} from "./actions";
import JSBI from "jsbi";
import useActiveWeb3React from "@/hooks/useActiveWeb3React";
import { useAllTokens } from "../../hooks/Tokens";
import { BASES_TO_TRACK_LIQUIDITY_FOR } from "../../constants/routing";
import { V2_FACTORY_ADDRESSES } from "../../constants/addresses";
import { computePairAddress } from "@drxswap/v2-sdk";
import { SupportedLocale } from "@/constants/locales";

function serializeToken(token: Token): SerializedToken {
  return {
    chainId: token.chainId,
    address: token.address,
    decimals: token.decimals,
    symbol: token.symbol,
    name: token.name,
  };
}

function deserializeToken(serializedToken: SerializedToken): Token {
  return new Token(
    serializedToken.chainId,
    serializedToken.address,
    serializedToken.decimals,
    serializedToken.symbol,
    serializedToken.name
  );
}
export function useClientSideRouter(): [
  boolean,
  (userClientSideRouter: boolean) => void
] {
  const dispatch = useAppDispatch();

  const clientSideRouter = useAppSelector((state) =>
    Boolean(state.user.userClientSideRouter)
  );

  const setClientSideRouter = useCallback(
    (newClientSideRouter: boolean) => {
      dispatch(
        updateUserClientSideRouter({
          userClientSideRouter: newClientSideRouter,
        })
      );
    },
    [dispatch]
  );

  return [clientSideRouter, setClientSideRouter];
}

export function useSetUserSlippageTolerance(): (
  slippageTolerance: Percent | "auto"
) => void {
  const dispatch = useAppDispatch();

  return useCallback(
    (userSlippageTolerance: Percent | "auto") => {
      let value: "auto" | number;
      try {
        value =
          userSlippageTolerance === "auto"
            ? "auto"
            : JSBI.toNumber(userSlippageTolerance.multiply(10_000).quotient);
      } catch (error) {
        value = "auto";
      }
      dispatch(
        updateUserSlippageTolerance({
          userSlippageTolerance: value,
        })
      );
    },
    [dispatch]
  );
}

export function useUserSlippageTolerance(): Percent | "auto" {
  const userSlippageTolerance = useAppSelector((state) => {
    return state.user.userSlippageTolerance;
  });

  return useMemo(
    () =>
      userSlippageTolerance === "auto"
        ? "auto"
        : new Percent(userSlippageTolerance, 10_000),
    [userSlippageTolerance]
  );
}

export function useUserSlippageToleranceWithDefault(
  defaultSlippageTolerance: Percent
): Percent {
  const allowedSlippage = useUserSlippageTolerance();
  return useMemo(
    () =>
      allowedSlippage === "auto" ? defaultSlippageTolerance : allowedSlippage,
    [allowedSlippage, defaultSlippageTolerance]
  );
}

export function useAddUserToken(): (token: Token) => void {
  const dispatch = useAppDispatch();
  return useCallback(
    (token: Token) => {
      dispatch(addSerializedToken({ serializedToken: serializeToken(token) }));
    },
    [dispatch]
  );
}

export function useRemoveUserAddedToken(): (
  chainId: number,
  address: string
) => void {
  const dispatch = useAppDispatch();
  return useCallback(
    (chainId: number, address: string) => {
      dispatch(removeSerializedToken({ chainId, address }));
    },
    [dispatch]
  );
}

export function useUserAddedTokens(): Token[] {
  const { chainId } = useActiveWeb3React();
  const serializedTokensMap = useAppSelector(({ user: { tokens } }) => tokens);

  return useMemo(() => {
    if (!chainId) return [];
    const tokenMap: Token[] = serializedTokensMap?.[chainId]
      ? Object.values(serializedTokensMap[chainId]).map(deserializeToken)
      : [];
    return tokenMap;
  }, [serializedTokensMap, chainId]);
}

export function useIsExpertMode(): boolean {
  return useAppSelector((state) => state.user.userExpertMode);
}

export function useExpertModeManager(): [boolean, () => void] {
  const dispatch = useAppDispatch();
  const expertMode = useIsExpertMode();

  const toggleSetExpertMode = useCallback(() => {
    dispatch(updateUserExpertMode({ userExpertMode: !expertMode }));
  }, [expertMode, dispatch]);

  return [expertMode, toggleSetExpertMode];
}

export function useUserTransactionTTL(): [number, (slippage: number) => void] {
  const dispatch = useAppDispatch();
  const userDeadline = useAppSelector((state) => state.user.userDeadline);
  const deadline = userDeadline;

  const setUserDeadline = useCallback(
    (userDeadline: number) => {
      dispatch(updateUserDeadline({ userDeadline }));
    },
    [dispatch]
  );

  return [deadline, setUserDeadline];
}

/**
 * Given two tokens return the liquidity token that represents its liquidity shares
 * @param tokenA one of the two tokens
 * @param tokenB the other token
 */
export function toV2LiquidityToken([tokenA, tokenB]: [Token, Token]): Token {
  if (tokenA.chainId !== tokenB.chainId)
    throw new Error("Not matching chain IDs");
  if (tokenA.equals(tokenB)) throw new Error("Tokens cannot be equal");
  if (!V2_FACTORY_ADDRESSES[tokenA.chainId])
    throw new Error("No V2 factory address on this chain");

  return new Token(
    tokenA.chainId,
    computePairAddress({
      factoryAddress: V2_FACTORY_ADDRESSES[tokenA.chainId],
      tokenA,
      tokenB,
    }),
    18,
    `${tokenA.symbol}-${tokenB.symbol}`,
    `${tokenA.name}-${tokenB.name}`
  );
}

/**
 * Returns all the pairs of tokens that are tracked by the user for the current chain ID.
 */
export function useTrackedTokenPairs(): [Token, Token][] {
  const { chainId } = useActiveWeb3React();
  const tokens = useAllTokens();

  // pairs for every token against every base
  const generatedPairs: [Token, Token][] = useMemo(
    () =>
      chainId
        ? Object.keys(tokens).flatMap((tokenAddress) => {
            const token = tokens[tokenAddress];
            // for each token on the current chain,
            return (
              // loop though all bases on the current chain
              (BASES_TO_TRACK_LIQUIDITY_FOR[chainId] ?? [])
                // to construct pairs of the given token with each base
                .map((base) => {
                  if (base.address === token.address) {
                    return null;
                  } else {
                    return [base, token];
                  }
                })
                .filter((p): p is [Token, Token] => p !== null)
            );
          })
        : [],
    [tokens, chainId]
  );

  // pairs saved by users
  const savedSerializedPairs = useAppSelector(({ user: { pairs } }) => pairs);

  const userPairs: [Token, Token][] = useMemo(() => {
    if (!chainId || !savedSerializedPairs) return [];
    const forChain = savedSerializedPairs[chainId];
    if (!forChain) return [];

    return Object.keys(forChain).map((pairId) => {
      return [
        deserializeToken(forChain[pairId].token0),
        deserializeToken(forChain[pairId].token1),
      ];
    });
  }, [savedSerializedPairs, chainId]);

  const combinedList = useMemo(
    () => userPairs.concat(generatedPairs),
    [generatedPairs, userPairs]
  );

  return useMemo(() => {
    // dedupes pairs of tokens in the combined list
    const keyed = combinedList.reduce<{ [key: string]: [Token, Token] }>(
      (memo, [tokenA, tokenB]) => {
        const sorted = tokenA.sortsBefore(tokenB);
        const key = sorted
          ? `${tokenA.address}:${tokenB.address}`
          : `${tokenB.address}:${tokenA.address}`;
        if (memo[key]) return memo;
        memo[key] = sorted ? [tokenA, tokenB] : [tokenB, tokenA];
        return memo;
      },
      {}
    );

    return Object.keys(keyed).map((key) => keyed[key]);
  }, [combinedList]);
}

export function useUserLocale(): SupportedLocale | null {
  return useAppSelector((state) => state.user.userLocale);
}

export function useUserLocaleManager(): [
  SupportedLocale | null,
  (newLocale: SupportedLocale) => void
] {
  const dispatch = useAppDispatch();
  const locale = useUserLocale();

  const setLocale = useCallback(
    (newLocale: SupportedLocale) => {
      dispatch(updateUserLocale({ userLocale: newLocale }));
    },
    [dispatch]
  );

  return [locale, setLocale];
}
