import JSBI from "jsbi";

export const FACTORY_ADDRESS_MAP = {
  85: "0x0323DE521C49307202832A9Bc3Dc43b28981fdE9",
  86: "0x71AC1fEc2c6Be0f02Eb890aFB9196812287DDc33",
};

export const INIT_CODE_HASH =
  "0x91612c9b6e590316a3bcf6b6279072de29529d2019af2da58a6ea1b1ffef7adb";

export const INIT_CODE_HASH_MAP = {
  85: INIT_CODE_HASH,
  86: INIT_CODE_HASH,
};

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000);

// exports for internal consumption
export const ZERO = JSBI.BigInt(0);
export const ONE = JSBI.BigInt(1);
export const FIVE = JSBI.BigInt(5);
export const _997 = JSBI.BigInt(997);
export const _1000 = JSBI.BigInt(1000);
