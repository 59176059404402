import { ReactElement, ReactNode } from "react";
import style from "./style.module.scss";
import cn, { Argument } from "classnames";
export interface TextProps {
  type?:
    | "primary"
    | "normal"
    | "reverse"
    | "secondary"
    | "danger"
    | "description"
    | "success"
    | "reward";
  size?: "" | "xs" | "sm" | "md" | "lg" | "lgr" | "xl" | "xxl";
  disable?: boolean;
  secondary?: boolean;
  emphases?: boolean;
  link?: boolean;
  bold?: boolean;
  clickable?: boolean;
  family?: "system" | "roboto";
  children: ReactNode;
  className?: Argument;
  elementType?: ReactElement["type"];
  [key: string]: ReactElement["props"];
}

export function Text({
  disable,
  type,
  size,
  bold,
  emphases,
  link,
  children,
  className,
  elementType = "div",
  family,
  clickable,
  ...props
}: TextProps) {
  const element = {
    type: elementType,
  };
  return (
    <element.type
      className={cn(
        {
          [style.primary]: type === "primary",
          [style.normal]: type === "normal",
          [style.secondary]: type === "secondary",
          [style.description]: type === "description",
          [style.danger]: type === "danger",
          [style.reverse]: type === "reverse",
          [style.success]: type === "success",
          [style.reward]: type === "reward",
          [style.middle]: size === "md",
          [style.small]: size === "sm",
          [style["x-small"]]: size === "xs",
          [style["xl"]]: size === "xl",
          [style["lg"]]: size === "lg",
          [style["lgr"]]: size === "lgr",
          [style["xxl"]]: size === "xxl",
          [style.disable]: disable,
          [style.emphases]: emphases,
          [style.bold]: bold,
          [style.system]: family === "system",
          [style.link]: link,
          [style.clickable]: clickable,
        },
        className
      )}
      {...props}
    >
      {children}
    </element.type>
  );
}

export default Text;
