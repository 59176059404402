import { Trans } from "@lingui/macro";
import style from "./style.module.scss";

export default function ComingSoonTag() {
  return (
    <span
      className={style.tag}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Trans>Coming Soon</Trans>
    </span>
  );
}
