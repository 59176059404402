import { ReactElement, ReactNode } from "react";
import style from "./style.module.scss";
import cn from "classnames";
export default function UICard({
  children,
  bodyStyle,
  cardStyle,
  className,
  bodyClassName,
  bordered = true,
}: {
  children: ReactNode;
  bodyStyle?: ReactElement["props"]["style"];
  cardStyle?: ReactElement["props"]["style"];
  className?: ReactElement["props"]["className"];
  bodyClassName?: ReactElement["props"]["className"];
  bordered?: boolean;
}) {
  return (
    <div
      className={cn(
        style.card,
        {
          [style.bordered]: bordered,
        },
        className
      )}
      style={cardStyle}
    >
      <div className={cn(style.cardBody, bodyClassName)} style={bodyStyle}>
        {children}
      </div>
    </div>
  );
}
