import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faTelegramPlane,
  faGithub,
  faMediumM,
} from "@fortawesome/free-brands-svg-icons";
import { ReactElement } from "react";
import UIAlign from "@/ui/Align";
import style from "./style.module.scss";
import classNames from "classnames";
import { useLessThanLaptop } from "@/utils/media";
import { Col, Row } from "antd";
import { t } from "@lingui/macro";

interface INavItem {
  icon: ReactElement;
  title: string;
  link: string;
  disabled?: boolean;
  type: string;
}

export default function Footer() {
  const isLessThanLaptop = useLessThanLaptop();

  const socialSection: Partial<INavItem>[] = [
    {
      icon: <FontAwesomeIcon icon={faTelegramPlane} />,
      link: "https://t.me/drxdefi",
      title: "telegram",
      type: "blue",
    },
    {
      icon: (
        <FontAwesomeIcon icon={faTwitter} width={isLessThanLaptop ? 32 : 48} />
      ),
      link: "https://twitter.com/DRx_DeFi",
      title: "twitter",
      type: "red",
    },
    {
      icon: (
        <FontAwesomeIcon icon={faMediumM} width={isLessThanLaptop ? 32 : 48} />
      ),
      link: "https://medium.com/@drxdefi",
      title: "medium",
      type: "blue",
    },
    {
      icon: (
        <FontAwesomeIcon icon={faGithub} width={isLessThanLaptop ? 32 : 48} />
      ),
      link: "https://github.com/DRxdefi",
      title: "github",
      type: "red",
    },
  ];
  return (
    <div className={style.footerContainer}>
      <UIAlign
        justify="between"
        wrap
        className={style.footer}
        gap={isLessThanLaptop ? 16 : 32}
        inline
        align="center"
      >
        {socialSection.map(({ icon, link, title, type }) => (
          <UIAlign
            align="center"
            justify="center"
            className={classNames(
              style.socialItem,
              type === "blue" ? style.blueItem : style.redItem
            )}
            href={link}
            target="_blank"
            rel="noreferrer"
            key={title}
            type="a"
          >
            {icon}
          </UIAlign>
        ))}
      </UIAlign>
      <Row
        align="middle"
        gutter={isLessThanLaptop ? 16 : 32}
        style={{ marginTop: 18 }}
      >
        <Col>
          <a href="https://beosin.com/audits/DRx_202204251636.pdf">
            {t`Audit Report 1`}
          </a>
        </Col>
        <Col>
          <a href="https://www.drxdefi.com/file/Smart_contract_security_audit_report_DRXswap.pdf">
            {t`Audit Report 2`}
          </a>
        </Col>
      </Row>
    </div>
  );
}
