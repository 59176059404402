import { Trans } from "@lingui/macro";
import {
  Currency,
  CurrencyAmount,
  Percent,
  TradeType,
} from "@drxswap/sdk-core";
import tryParseCurrencyAmount from "../../utils/tryParseCurrencyAmount";
import { ParsedQs } from "qs";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { Trade as V2Trade } from "@drxswap/v2-sdk";

import { useCurrency } from "../../hooks/Tokens";
import { isAddress } from "@/utils/web3";
import { AppState } from "@/state";
import { useCurrencyBalances } from "../../hooks/useCurrencyBalance";
import {
  Field,
  replaceSwapState,
  selectCurrency,
  switchCurrencies,
  typeInput,
} from "./actions";
import { SwapState } from "./reducer";
import useParsedQueryString from "../../hooks/useParsedQueryString";
import useActiveWeb3React from "@/hooks/useActiveWeb3React";
import { useBestV2Trade } from "../../hooks/useBestV2Trade";
import useSwapSlippageTolerance from "../../hooks/useSwapSlippageTolerance";
import { BASE_CURRENCY, NATIVE_BASE_CURRENCY } from "../../constants/swap";

export function useSwapState(): AppState["swap"] {
  return useAppSelector((state) => state.swap);
}

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency) => void;
  onSwitchTokens: () => void;
  onUserInput: (field: Field, typedValue: string) => void;
} {
  const dispatch = useAppDispatch();
  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      dispatch(
        selectCurrency({
          field,
          currencyId: currency.isToken
            ? currency.address
            : currency.isNative
            ? NATIVE_BASE_CURRENCY
            : "",
        })
      );
    },
    [dispatch]
  );

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies());
  }, [dispatch]);

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }));
    },
    [dispatch]
  );

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
  };
}

const BAD_RECIPIENT_ADDRESSES: { [address: string]: true } = {
  "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f": true, // v2 factory
  "0xf164fC0Ec4E93095b804a4795bBe1e041497b92a": true, // v2 router 01
  "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D": true, // v2 router 02
};

// from the current swap inputs, compute the best trade and return it.
export function useDerivedSwapInfo(): {
  currencies: { [field in Field]?: Currency | null };
  currencyBalances: { [field in Field]?: CurrencyAmount<Currency> };
  parsedAmount: CurrencyAmount<Currency> | undefined;
  inputError?: ReactNode;
  bestTrade: V2Trade<Currency, Currency, TradeType> | undefined;
  allowedSlippage: Percent;
} {
  const { account } = useActiveWeb3React();

  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState();

  const inputCurrency = useCurrency(inputCurrencyId);
  const outputCurrency = useCurrency(outputCurrencyId);

  const to = account ?? null;

  const relevantTokenBalances = useCurrencyBalances(
    account ?? undefined,
    useMemo(
      () => [inputCurrency ?? undefined, outputCurrency ?? undefined],
      [inputCurrency, outputCurrency]
    )
  );

  const isExactIn: boolean = independentField === Field.INPUT;
  const parsedAmount = useMemo(
    () =>
      tryParseCurrencyAmount(
        typedValue,
        (isExactIn ? inputCurrency : outputCurrency) ?? undefined
      ),
    [inputCurrency, isExactIn, outputCurrency, typedValue]
  );

  const bestTrade = useBestV2Trade(
    isExactIn ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
    parsedAmount,
    (isExactIn ? outputCurrency : inputCurrency) ?? undefined
  );

  const currencyBalances = useMemo(
    () => ({
      [Field.INPUT]: relevantTokenBalances[0],
      [Field.OUTPUT]: relevantTokenBalances[1],
    }),
    [relevantTokenBalances]
  );

  const currencies: { [field in Field]?: Currency | null } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency,
      [Field.OUTPUT]: outputCurrency,
    }),
    [inputCurrency, outputCurrency]
  );

  // allowed slippage is either auto slippage, or custom user defined slippage if auto slippage disabled
  const allowedSlippage = useSwapSlippageTolerance(bestTrade ?? undefined);

  const inputError = useMemo(() => {
    let inputError: ReactNode | undefined;

    if (!account) {
      inputError = <Trans>Connect Wallet</Trans>;
    }

    if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
      inputError = inputError ?? <Trans>Select a token</Trans>;
    }

    if (!parsedAmount) {
      inputError = inputError ?? <Trans>Enter an amount</Trans>;
    }

    const formattedTo = isAddress(to);
    if (!to || !formattedTo) {
      inputError = inputError ?? <Trans>Enter a recipient</Trans>;
    } else {
      if (BAD_RECIPIENT_ADDRESSES[formattedTo]) {
        inputError = inputError ?? <Trans>Invalid recipient</Trans>;
      }
    }

    // compare input balance to max input based on version
    const [balanceIn, amountIn] = [
      currencyBalances[Field.INPUT],
      bestTrade?.maximumAmountIn(allowedSlippage),
    ];

    if (balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
      inputError = (
        <Trans>Insufficient {amountIn.currency.symbol} balance</Trans>
      );
    }

    return inputError;
  }, [
    account,
    allowedSlippage,
    bestTrade,
    currencies,
    currencyBalances,
    parsedAmount,
    to,
  ]);

  return useMemo(
    () => ({
      currencies,
      currencyBalances,
      parsedAmount,
      inputError,
      bestTrade: bestTrade ?? undefined,
      allowedSlippage,
    }),
    [
      allowedSlippage,
      currencies,
      currencyBalances,
      inputError,
      parsedAmount,
      bestTrade,
    ]
  );
}

function parseCurrencyFromURLParameter(urlParam: any): string {
  if (typeof urlParam === "string") {
    const valid = isAddress(urlParam);
    if (valid) return valid;
    if (urlParam.toUpperCase() === NATIVE_BASE_CURRENCY)
      return NATIVE_BASE_CURRENCY;
  }
  return "";
}

function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === "string" && !isNaN(parseFloat(urlParam))
    ? urlParam
    : "";
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === "string" && urlParam.toLowerCase() === "output"
    ? Field.OUTPUT
    : Field.INPUT;
}

export function queryParametersToSwapState(parsedQs: ParsedQs): SwapState {
  let inputCurrency = parseCurrencyFromURLParameter(parsedQs.inputCurrency);
  let outputCurrency = parseCurrencyFromURLParameter(parsedQs.outputCurrency);
  if (inputCurrency === "" && outputCurrency === "") {
    inputCurrency = NATIVE_BASE_CURRENCY;
    outputCurrency = BASE_CURRENCY;
  } else if (inputCurrency === outputCurrency) {
    // clear output if identical
    outputCurrency = "";
  }

  return {
    [Field.INPUT]: {
      currencyId: inputCurrency === "" ? null : inputCurrency ?? null,
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency === "" ? null : outputCurrency ?? null,
    },
    typedValue: parseTokenAmountURLParameter(parsedQs.exactAmount),
    independentField: parseIndependentFieldURLParameter(parsedQs.exactField),
  };
}

// updates the swap state to use the defaults for a given network
export function useDefaultsFromURLSearch():
  | {
      inputCurrencyId: string | undefined;
      outputCurrencyId: string | undefined;
    }
  | undefined {
  const { chainId } = useActiveWeb3React();
  const dispatch = useAppDispatch();
  const parsedQs = useParsedQueryString();
  const [result, setResult] = useState<
    | {
        inputCurrencyId: string | undefined;
        outputCurrencyId: string | undefined;
      }
    | undefined
  >();

  useEffect(() => {
    if (!chainId) return;
    const parsed = queryParametersToSwapState(parsedQs);
    const inputCurrencyId = parsed[Field.INPUT].currencyId ?? undefined;
    const outputCurrencyId = parsed[Field.OUTPUT].currencyId ?? undefined;

    dispatch(
      replaceSwapState({
        typedValue: parsed.typedValue,
        field: parsed.independentField,
        inputCurrencyId,
        outputCurrencyId,
      })
    );

    setResult({ inputCurrencyId, outputCurrencyId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, chainId]);

  return result;
}
