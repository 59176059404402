import { getAddress } from "@ethersproject/address";
import { AddressZero } from "@ethersproject/constants";
import { Contract } from "@ethersproject/contracts";
import { namehash } from "@ethersproject/hash";
import { JsonRpcSigner, Web3Provider } from "@ethersproject/providers";

export function checkIsInDAppBrowser() {
  return (
    window?.ethereum?.isImToken ||
    window?.ethereum?.isTokenPocket ||
    (window?.ethereum?.isMetaMask &&
      /iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
  );
}

export function checkIsImToken() {
  return window?.ethereum?.isImToken;
}

// account is not optional
function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
function getProviderOrSigner(
  library: Web3Provider,
  account?: string
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

// account is optional
export function getContract(
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(
    address,
    ABI,
    getProviderOrSigner(library, account) as any
  );
}

export function isAddress(value: any): string | false {
  try {
    return getAddress(value);
  } catch (e) {
    return false;
  }
}

export function safeNamehash(name?: string): string | undefined {
  if (name === undefined) return undefined;

  try {
    return namehash(name);
  } catch (error) {
    console.debug(error);
    return undefined;
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
}
