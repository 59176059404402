import { Ether, NativeCurrency, Token, WETH9 } from "@drxswap/sdk-core";

import { SupportedChainId } from "@/constants/chains";

export const USDG: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(
    SupportedChainId.MAINNET,
    "0x35f669463BABE838e3cD3dDaB41885c334c892Ef",
    6,
    "USDG",
    "Gate USD"
  ),
  [SupportedChainId.METEORA]: new Token(
    SupportedChainId.METEORA,
    "0xDe2471B959aA4824DD48bBe50710Fee38Af79AD5",
    18,
    "USDG",
    "Gate USDG"
  ),
};

export const DRx: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(
    SupportedChainId.MAINNET,
    "0xc5c0B04e2D169FBec3477805b228dD17dc8c4E06",
    18,
    "DRx",
    "DRx"
  ),
  [SupportedChainId.METEORA]: new Token(
    SupportedChainId.METEORA,
    "0xc5c0B04e2D169FBec3477805b228dD17dc8c4E06",
    18,
    "DRx",
    "DRx"
  ),
};

export const Burn_DRx: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(
    SupportedChainId.MAINNET,
    "0x04e388163206A7Cd6F1b4c8a35d57bef579c3655",
    18,
    "DRx",
    "DRx"
  ),
  [SupportedChainId.METEORA]: new Token(
    SupportedChainId.METEORA,
    "0x04e388163206A7Cd6F1b4c8a35d57bef579c3655",
    18,
    "DRx",
    "DRx"
  ),
};

export const WRAPPED_NATIVE_CURRENCY: { [chainId: number]: Token } = {
  ...WETH9,
};

export class ExtendedEther extends Ether {
  public get wrapped(): Token {
    if (this.chainId in WRAPPED_NATIVE_CURRENCY)
      return WRAPPED_NATIVE_CURRENCY[this.chainId];
    throw new Error("Unsupported chain ID");
  }

  private static _cachedExtendedEther: { [chainId: number]: NativeCurrency } =
    {};

  public static onChain(chainId: number): ExtendedEther {
    return (
      this._cachedExtendedEther[chainId] ??
      (this._cachedExtendedEther[chainId] = new ExtendedEther(chainId))
    );
  }
}

const cachedNativeCurrency: { [chainId: number]: NativeCurrency } = {};
export function nativeOnChain(chainId: number): NativeCurrency {
  return (
    cachedNativeCurrency[chainId] ??
    (cachedNativeCurrency[chainId] = ExtendedEther.onChain(chainId))
  );
}

export const ADDRESS_ZERO = "0x0000000000000000000000000000000000000000";

export const ADDRESS_EXTRA = "0x000000000000000000000000000000000000dEaD";
