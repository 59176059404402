import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { Web3ReactProvider } from "@web3-react/core";
import getLibrary from "./utils/getLibrary";
import { BrowserRouter } from "react-router-dom";
import { LanguageProvider } from "./i18n";
import { ThemeProvider } from "./theme";

// 避免 CSS 覆盖，最后引入 App
import App from "./pages/App";
import { Provider } from "react-redux";
import store from "./state";
import Updaters from "./state/Updater";
import { useEagerConnect } from "./hooks/useEagerConnect";
import { useAccountEventListener } from "./hooks/useAccountEventListener";
import useConnectError from "./components/Modal/WalletModal/hooks/useConnectError";

global.Buffer = global.Buffer || require("buffer").Buffer;

function GlobalHooks() {
  useEagerConnect();
  useAccountEventListener();
  useConnectError();
  return null;
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider>
          <LanguageProvider>
            <Web3ReactProvider getLibrary={getLibrary}>
              <GlobalHooks />
              <Updaters />
              <App />
            </Web3ReactProvider>
          </LanguageProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
