import { createReducer } from "@reduxjs/toolkit";
import { IPool, PoolAddress } from "../../types";

// import { updateVersion } from "@/state/global/actions";
import {
  fetchAddressList,
  updateAddresses,
  updatePoolList,
  updatePoolDetail,
} from "./actions";

const currentTimestamp = () => new Date().getTime();

export interface PoolState {
  // the timestamp of the last updateVersion action
  addresses: PoolAddress[];
  poolList: {
    [address: string]: IPool;
  };
  timestamp: number;
  poolDetail: IPool | undefined;
}

export const initialState: PoolState = {
  addresses: [],
  poolList: {},
  timestamp: currentTimestamp(),
  poolDetail: undefined,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateAddresses, (state, action) => {
      state.addresses = action.payload.addresses;
      state.timestamp = currentTimestamp();
    })
    .addCase(updatePoolList, (state, action) => {
      state.poolList = action.payload.pools;
      state.timestamp = currentTimestamp();
    })
    .addCase(
      fetchAddressList.pending,
      (state, { payload: { requestId, url } }) => {
        // todo pending state
      }
    )
    .addCase(
      fetchAddressList.fulfilled,
      (state, { payload: { requestId, addresses, url } }) => {
        state.addresses = addresses;
      }
    )
    .addCase(
      fetchAddressList.rejected,
      (state, { payload: { url, requestId, errorMessage } }) => {
        // if (state.byUrl[url]?.loadingRequestId !== requestId) {
        //   // no-op since it's not the latest request
        //   return;
        // }

        state.addresses = [];
      }
    )
    .addCase(updatePoolDetail, (state, action) => {
      state.poolDetail = action.payload.poolDetail;
      state.timestamp = currentTimestamp();
    })
);
