import { Layout as AntdLayout } from "antd";
import style from "./style.module.scss";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet, useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";

export default function Layout() {
  const location = useLocation();
  useLayoutEffect(() => {
    if (["pool", "farm"].includes(location.pathname.split("/")[1])) {
      document.querySelector("#layout-content")?.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
    <AntdLayout className={style.container}>
      <AntdLayout.Header className={style.header}>
        <Header />
      </AntdLayout.Header>
      <AntdLayout>
        <AntdLayout.Content className={style.content} id="layout-content">
          <Outlet />
          <Footer />
        </AntdLayout.Content>
      </AntdLayout>
    </AntdLayout>
  );
}
