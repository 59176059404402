import { ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { FarmPoolState } from "./reducer";

export const fetchFarmContract: Readonly<{
  pending: ActionCreatorWithPayload<{ requestId: string }>;
  fulfilled: ActionCreatorWithPayload<{
    farmContract: {
      version: string;
      contracts: [
        {
          symbol: string;
          address: string;
        }
      ];
    };
    requestId: string;
  }>;
  rejected: ActionCreatorWithPayload<{
    error: string;
    requestId: string;
  }>;
}> = {
  pending: createAction("farm/fetchFarmContract/pending"),
  fulfilled: createAction("farm/fetchFarmContract/fulfilled"),
  rejected: createAction("farm/fetchFarmContract/rejected"),
};

export const updatePool = createAction<{
  address: string;
  poolInfo: Partial<FarmPoolState>;
}>("farm/updatePool");

export const updatePools =
  createAction<Array<{ address: string; pool: Partial<FarmPoolState> }>>(
    "farm/updatePools"
  );
