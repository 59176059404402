import "./animation.scss";
import style from "./style.module.scss";
import { useCallback, useEffect, useRef } from "react";
import { useActivePopups, useRemovePopup } from "@/state/application/hooks";
import { PopupContent } from "@/state/application/reducer";
import { ReactComponent as CloseIcon } from "@/assets/svg/close.svg";
import UICard from "@/ui/Card";
import TransactionPopup from "./TransactionPopup";
import { CSSMotionList } from "rc-motion";

export default function Popups() {
  const noticePropsMap = useRef<
    Record<
      string,
      {
        content: PopupContent;
        removeAfterMs: number | null;
      }
    >
  >({});
  const popupKeys: string[] = [];
  const activePopups = useActivePopups();
  activePopups.forEach((item) => {
    popupKeys.push(item.key);
    noticePropsMap.current[item.key] = {
      content: item.content,
      removeAfterMs: item.removeAfterMs,
    };
  });
  return (
    <div className={style.popups}>
      <CSSMotionList
        keys={popupKeys}
        motionName="popup-fade"
        onVisibleChanged={(changedVisible, { key }) => {
          if (!changedVisible) {
            delete noticePropsMap.current[key];
          }
        }}
      >
        {({ key, className: motionClassName, style: motionStyle, visible }) => {
          const item = noticePropsMap.current[key];
          return (
            <div className={motionClassName} style={motionStyle} key={key}>
              <PopupItem
                content={item.content}
                popKey={key}
                removeAfterMs={item.removeAfterMs}
              />
            </div>
          );
        }}
      </CSSMotionList>
    </div>
  );
}

function PopupItem({
  removeAfterMs,
  content,
  popKey,
}: {
  removeAfterMs: number | null;
  content: PopupContent;
  popKey: string;
}) {
  const removePopup = useRemovePopup();
  const removeThisPopup = useCallback(
    () => removePopup(popKey),
    [popKey, removePopup]
  );
  useEffect(() => {
    if (removeAfterMs === null) return undefined;

    const timeout = setTimeout(() => {
      removeThisPopup();
    }, removeAfterMs);

    return () => {
      clearTimeout(timeout);
    };
  }, [removeAfterMs, removeThisPopup]);

  let popupContent;
  if ("txn" in content) {
    const {
      txn: { hash },
    } = content;
    popupContent = <TransactionPopup hash={hash} />;
  } else {
    popupContent = null;
  }

  return (
    <UICard
      bodyStyle={{
        padding: "16px",
      }}
      bordered={false}
      className={style.popup}
    >
      <div onClick={removeThisPopup} className={style.closeIcon}>
        <CloseIcon />
      </div>
      {popupContent}
      {removeAfterMs !== null ? null : null}
    </UICard>
  );
}
