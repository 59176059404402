import style from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import LogoIcon from "@/assets/images/logo/DRxLogo.png";
import { Button, Menu, Dropdown, Space } from "antd";
import { t, Trans } from "@lingui/macro";
import WalletModal from "@/components/Modal/WalletModal";
import { ReactComponent as WalletIcon } from "@/assets/svg/wallet.svg";
import { ReactComponent as DisconnectIcon } from "@/assets/svg/disconnect.svg";
import { ReactComponent as MenuIcon } from "@/assets/svg_new/menu_mobile.svg";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { formatAccount } from "@/utils/format";
import LanguageSwitcher from "./LanguageSwitcher";
import UIAlign from "@/ui/Align";
import { useModalOpen, useWalletModalToggle } from "@/state/application/hooks";
import { ApplicationModal } from "@/state/application/reducer";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { checkIsInDAppBrowser } from "@/utils/web3";
import WalletConnectForDAppBrowser from "@/components/Modal/WalletModal/DAppBrowser";
import { useLessThanLaptop } from "@/utils/media";

interface INavItem {
  title: string;
  link: string;
  disabled?: boolean;
  isMobile?: boolean;
}

export default function Header() {
  const isWalletModalOpen = useModalOpen(ApplicationModal.WALLET);
  const toggleWalletModal = useWalletModalToggle(); // toggle wallet when disconnected

  const { active, account, error } = useWeb3React();
  const isConnected = active && account;
  const isWrongNetwork = error && error instanceof UnsupportedChainIdError;

  const financeSection: INavItem[] = [
    {
      title: t`Home`,
      link: "home",
    },
    {
      title: t`Swap`,
      link: "swap",
    },
    {
      title: t`IDO`,
      link: "ido",
    },
    {
      title: t`Pool`,
      link: "pool",
    },
    {
      title: t`Farm`,
      link: "farm",
    },
  ];

  const menu = (
    <Menu className={style.menuContainer}>
      <Menu.Item key="disconnect">
        <UIAlign
          align="center"
          gap={8}
          className={style.disconnectBtn}
          onClick={toggleWalletModal}
        >
          <DisconnectIcon />
          <Trans>Disconnect</Trans>
        </UIAlign>
      </Menu.Item>
    </Menu>
  );

  const menu_mobile = (
    <Menu className={style.mobileMenuContainer}>
      {financeSection.map((section) => (
        <Menu.Item key={section.title}>
          <NavItem {...section} key={section.title} isMobile={true} />
        </Menu.Item>
      ))}
    </Menu>
  );

  const isLessThanLaptop = useLessThanLaptop();

  return (
    <>
      <UIAlign direction="row" justify="between" align="center">
        <a href="/" className={style.logo}>
          <img src={LogoIcon} alt="logo icon" />
        </a>
        {!isLessThanLaptop && (
          <UIAlign direction="row" justify="between" align="center" gap={9}>
            {financeSection.map((item) => (
              <NavItem {...item} key={item.title} />
            ))}
          </UIAlign>
        )}
        <UIAlign
          direction="row"
          gap={isLessThanLaptop ? 12 : 32}
          align="center"
        >
          {isWrongNetwork ? (
            <Button
              onClick={toggleWalletModal}
              type="primary"
              danger
              size="large"
              className={style.connectBtn}
            >
              <Space align="center">
                <FontAwesomeIcon icon={faExclamationTriangle} />
                {t`Network`}
              </Space>
            </Button>
          ) : isConnected ? (
            <Dropdown
              overlay={menu}
              placement="bottomCenter"
              trigger={["click", "hover"]}
            >
              <UIAlign align="center" gap={12} className={style.walletAddress}>
                <WalletIcon />
                <label>{formatAccount(account)}</label>
              </UIAlign>
            </Dropdown>
          ) : (
            <Button
              className={style.connectBtn}
              onClick={toggleWalletModal}
              type="primary"
              size="large"
            >
              {isLessThanLaptop ? t`Connect` : t`Connect Wallet`}
            </Button>
          )}
          <LanguageSwitcher />
          {isLessThanLaptop && (
            <Dropdown
              overlay={menu_mobile}
              placement="bottomRight"
              trigger={["click", "hover"]}
            >
              <MenuIcon />
            </Dropdown>
          )}
        </UIAlign>
      </UIAlign>
      {checkIsInDAppBrowser() ? (
        <WalletConnectForDAppBrowser
          trigger={isWalletModalOpen}
          isConnectedMode={!!isConnected}
          onActionDone={toggleWalletModal}
        />
      ) : (
        <WalletModal
          visible={isWalletModalOpen}
          closeModal={toggleWalletModal}
          isConnected={!!isConnected}
          isWrongNetwork={!!isWrongNetwork}
        />
      )}
    </>
  );
}

function NavItem({ title, link, disabled, isMobile }: INavItem) {
  const itemCls = cn(isMobile ? style.mobileItem : style.item, {
    [style.disabled]: disabled,
  });
  if (isExternalLink(link)) {
    return (
      <a href={link} className={itemCls} target="_blank" rel="noreferrer">
        {title}
      </a>
    );
  }
  return (
    <NavLink
      className={({ isActive }) =>
        cn(itemCls, {
          [style.active]: isActive,
        })
      }
      to={link}
    >
      {title}
    </NavLink>
  );
}

function isExternalLink(link: string): boolean {
  return link.toString().includes("://");
}
