// a list of tokens by chain
import { Currency, Token } from "@drxswap/sdk-core";

import { SupportedChainId } from "@/constants/chains";
import { USDG, DRx, nativeOnChain, WRAPPED_NATIVE_CURRENCY } from "./tokens";

type ChainTokenList = {
  readonly [chainId: number]: Token[];
};

type ChainCurrencyList = {
  readonly [chainId: number]: Currency[];
};

const WRAPPED_NATIVE_CURRENCIES_ONLY: ChainTokenList = Object.fromEntries(
  Object.entries(WRAPPED_NATIVE_CURRENCY).map(([key, value]) => [key, [value]])
);

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WRAPPED_NATIVE_CURRENCIES_ONLY,
  [SupportedChainId.MAINNET]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.MAINNET],
    USDG[SupportedChainId.MAINNET],
    DRx[SupportedChainId.MAINNET],
  ],
  [SupportedChainId.METEORA]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.METEORA],
    USDG[SupportedChainId.METEORA],
    DRx[SupportedChainId.METEORA],
  ],
};
/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: {
  [chainId: number]: { [tokenAddress: string]: Token[] };
} = {
  [SupportedChainId.MAINNET]: {
    // [AMPL.address]: [DAI, WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET]],
  },
};

/**
 * Shows up in the currency select for swap and add liquidity
 */
export const COMMON_BASES: ChainCurrencyList = {
  [SupportedChainId.MAINNET]: [
    nativeOnChain(SupportedChainId.MAINNET),
    WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET],
    USDG[SupportedChainId.MAINNET],
    DRx[SupportedChainId.MAINNET],
  ],
  [SupportedChainId.METEORA]: [
    nativeOnChain(SupportedChainId.METEORA),
    WRAPPED_NATIVE_CURRENCY[SupportedChainId.METEORA],
    USDG[SupportedChainId.METEORA],
    DRx[SupportedChainId.METEORA],
  ],
};

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WRAPPED_NATIVE_CURRENCIES_ONLY,
  [SupportedChainId.MAINNET]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.MAINNET],
    USDG[SupportedChainId.MAINNET],
    DRx[SupportedChainId.MAINNET],
  ],
  [SupportedChainId.METEORA]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.METEORA],
    USDG[SupportedChainId.METEORA],
    DRx[SupportedChainId.METEORA],
  ],
};
